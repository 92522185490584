@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorLightBlue, colorLightBlue2,colorDarkBlue, colorMediumBlue, colorYellow from './../../../variables/colors.css';
@value space3, space5, space9, space15, space18, space20, space25 from './../../../variables/spaces.css';
@value dividerSize, borderSize, size11, size14, size18, size60 from "./../../../variables/sizes.css";

.sessionListWrapper {
  height: 72vh;
  /* height: 100%; */
}

.sessionHolder {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sessionTitleHolder {
  flex: 0 0 auto;
  background-color: colorDarkBlue;
  padding: space18;
  color: colorWhite;
  top: 0;
  left: 0;
  right: 0;
}

.title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sessionHolder h2 {
  font-size: size18;
}
.sessionListHolder {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}
.sessionList {
  /*padding-top: 10px;*/
}

.titleHolder h3 {
  color: colorMediumBlue;
  font-size: size18;
}

.sessionList hr {
  background-color: transparent;
  border-top: dividerSize solid colorLightGrey;
  margin-bottom: 0;
}

.session {
  padding-left: space18;
  padding-right: space18;
  padding-top: space18;
}

.buttonHolder {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
  margin-top: space18;
}

.optionButtonContainer {
  display: flex;
  padding-left: space15;
  justify-content: center;
  align-items: center;
}

.paddedButtonContainer {
  margin-right: space15;
}

.pastButtonHolder button {
  margin-top: space18;
  margin-left: space18;
}

.contentHolder {
  display: flex;
}

.titleHolder {
  flex: 1 1 auto;
}

.iconContainer {
  /* flex: 0 0 60px; */
  height: size60;
  display: flex;
  align-items: flex-end;
  padding-right: space15;
  /* padding-left: space20; */
}

.nonLtc {
  margin-left: size8;
  width: size60;
  height: size60;
}

.spinnerHolder {
  text-align: center;
}

.spinner {
  width: 3rem !important;
  height: 3rem !important;

  text-align: center;
  margin-top: space18;
}

.timeContainer {
  /* font: 16px; */
  margin-top: 5px;
  color: colorMediumBlue;
}
