.pagination {
  padding-left: 12px !important;
  padding-right: 12px !important;
  margin: 5px;
}

.pageNumber {
  margin: auto 0;
  font-weight: bold;
  font-size: 18px;
}
