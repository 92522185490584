@value space3, space9, space18 from "./../../variables/spaces.css";
@value colorYellow, colorDarkBlue, colorWhite from "./../../variables/colors.css";
@value size14 from "./../../variables/sizes.css";

.footer {
  background-color: var(--colorDarkBlue);
  text-align: center;
  margin-top: auto;
  background-color: colorDarkBlue;
  font: normal 1rem Roboto;
}

.footer a {
  color: colorWhite;
}

.footer a:hover {
  color: colorYellow;
  text-decoration: none;
}

.footerLeft {
  flex: 0 0 45%;
  margin-top: auto;
}
.activeLink,
.contactLink:hover {
  color: colorYellow !important;
}

.buildInfo {
  color: colorWhite;
  margin-bottom: 0;
  margin-right: space18;
  margin-top: space3;
  font-size: size14;
}

#ltcLogo {
  margin: 0 20px;
  max-width: 150px;
}
.footerLeft > a {
  display: inline-block;
  margin-bottom: space9;
}

.footerLeft ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.footerLeft ul li {
  flex: 0 0 33%;
}
.footerLeft {
  text-align: left !important;
  margin-left: 2%;
}

.footerLeft .activeLink {
  color: colorYellow;
}
.footerContent {
  display: flex;
  align-items: center;
  padding-top: space9;
  padding-bottom: space9;
}

.footerRight {
  text-align: right;
  flex: 1 1 auto;
}

.footerRight ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  display: inline-flex;
}

.footerRight ul li {
  float: left;
}

.footerRight ul li a {
  display: block;
  padding: 0 space18;
}

.footerRight ul li:not(:last-child) a {
  border-right: solid 1px colorWhite;
}
#ltcLogo {
  margin: 0 20px;
}
