@value colorDarkBlue from '../../../../../../variables/colors.css';
@value borderSize, size5, size11, size14, size18 from "../../../../../../variables/sizes.css";

.cancelLabel {
  color: colorDarkBlue;
  font-weight: bold;
  font-size: size14;
}

.cancelIndicator {
  color: colorDarkBlue;
}
