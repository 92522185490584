@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorLightBlue2, colorDarkBlue, colorMediumBlue, colorYellow from '../../../../variables/colors.css';
@value space5, space9, space18 from '../../../../variables/spaces.css';
@value size11, size14, size18 from "../../../../variables/sizes.css";

.spinnerContainer {
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dashboardItem {
  border-radius: space9;
  height: 63vh;
  border: 20px solid transparent;
  padding: 0px 0px 4px 0px;
  border-image: url(../../../../assets/MyDashboardPanel.png) 40 50 50 50 stretch;
}

.highlighted.dashboardItem {
  border-image: url(../../../../assets/MyDashboardPanel_highlighted.png) 40 50 50 50
    stretch;
}

.dashboardItemCollapsed {
  border: 20px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid transparent;
  border-image: url(../../../../assets/MyDashboardPanelCollapsed.svg) 27% 4% 9.2% 5%
    stretch;
  background-clip: content-box;
  background-color: colorLightBlue2;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: colorDarkBlue;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboardItemCollapsed h2 {
  padding: space9;
  font-size: size18;
  text-align: center;
}

.children {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
}

.container {
  height: 100%;
  background-color: colorWhite;
  display: flex;
  flex-direction: column;
}

.titleHolder {
  display: flex;
  background-color: colorMediumBlue;
  color: colorWhite;
  font-size: size18;
}

.highlighted .titleHolder {
  color: colorDarkBlue;
  background-color: colorYellow;
}

.titleHolder h2 {
  padding: space9;
  font-size: size18;
  text-align: center;
}

.headingItemsContainer {
  display: flex;
  text-align: center;
  background-color: colorLightBlue2;
}

.headingItemsContainer h2 {
  /* flex: 1 1 auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.collapseButtonHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 space5;
}

.innerTitleHolder {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.collapsible .innerTitleHolder {
  cursor: pointer;
}

.collapsible .titleHolder {
  cursor: pointer;
}

.expand {
  opacity: 0.5;
  transform: scaleY(0.3) translateY(-50rem);
}

.expandActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 400ms, transform 400ms, background-color 400ms;
}

.collapse {
  opacity: 0.5;
  transform: scaleY(1.2) translateY(2rem);
}

.collapseActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 400ms, transform 400ms, background-color 400ms;
}

.expandCollapseButton:hover {
  filter: brightness(109%);
  transition: all 200ms;
}
