@value colorWhite, colorMediumBlue, colorDarkBlue, colorLightGrey, colorYellow from './../../../variables/colors.css';
@value borderSize, borderRadius, borderRadius6, size16, size18, size24 from './../../../variables/sizes.css';
@value space1, space3, space5, space10, space15, space20, space25, space30, space36, space40 from './../../../variables/spaces.css';

.courseContainer {
  display: flex;
  flex-direction: column;
  border-radius: borderRadius;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  background-color: colorWhite;
  height: 72vh;
}

.coursePageBanner {
  object-fit: cover;
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1 72vh;
  height: 100%;
  position: relative;
}

.headerContainer {
  background-color: colorDarkBlue;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sessionTitle {
  padding: space15 space36 space15 space25;
  font-size: size18;
  font-weight: 500;
  color: colorWhite;
  flex: 1 1 auto;
}

.colHeaderRow {
  margin-left: 0;
  margin-right: 0;
  color: colorDarkBlue;
  font-weight: 500;
  padding-top: space3;
}

.colFlexHeaderTitle {
  display: flex;
  align-items: center;
}

.colFlexHeaderTitle div {
  padding-top: space3;
  padding-bottom: space3;
}

.colBlockHeaderTitle {
  display: block;
  align-items: center;
}

.icon {
  align-self: flex-start;
  padding-right: space5;
  padding-top: space3;
  padding-bottom: space5;
}

.mandatoryContainer {
  margin-right: space40;
}

.borderLeft {
  border-left: borderSize solid colorLightGrey;
}

.listArea {
  color: colorDarkBlue;
  overflow: hidden;
  height: 100%;
  padding: 0 space15 0 space15;
}

.listContainer {
  max-height: 100%;
  overflow-y: scroll;
  border-bottom: borderSize solid colorLightGrey;
  border-top: borderSize solid colorLightGrey;
  flex: 1 0 120px;
  min-height: 100%;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: space15;
}

.staffRow {
  margin-top: space10;
  padding-left: space15;
  padding-right: space15;
}

.staffRowCheckbox {
  padding-top: space10;
}

.selectAll {
  font-size: size18;
  font-weight: bold;
  color: colorMediumBlue;
}

/* scrollBar */
.listContainer::-webkit-scrollbar {
  width: size16;
}

.listContainer::-webkit-scrollbar-track {
  border-left: borderSize solid colorLightGrey;
}

.listContainer::-webkit-scrollbar-thumb {
  background: colorDarkBlue;
  border-radius: borderRadius6;
}
/***/

.buttonRow {
  padding-left: space30;
}

.actionBtn {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.actionButtonContainer {
  padding: space10 space30 space10 0;
}

.darkBlueColor {
  color: colorDarkBlue;
}

.loadingSpinner {
  text-align: center;
  margin-top: space10;
}

.sentSpinner {
  margin-left: space10;
}

.disabledMessage {
  font-weight: normal;
  font-style: italic;
  margin-left: size24;
  color: #6c757d;
}
