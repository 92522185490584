@value colorPink from "./../../variables/colors.css";

.accessibleButton > span {
  display: block;
}

.accessibleButton:focus {
  box-shadow: 0 0 2px 2px colorPink;
}

.accessibleButton:disabled,
.accessibleButton[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.75;
  font-weight:bold;
}

/* Removing default outline only after we've added our custom one */
.accessibleButton:focus,
.accessibleButton > span:focus {
  outline: none;
}
