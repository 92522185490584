/*body {*/
/*margin: 0;*/
/*padding: 0;*/
/*font-family: sans-serif;*/
/*}*/

html,
body,
#app,
#app > div,
#root {
  height: 100%;
  min-width: 1250px;
}

* {
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
