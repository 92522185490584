@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorDarkBlue, colorMediumBlue, colorYellow from '../../../../variables/colors.css';
@value space9, space18 from '../../../../variables/spaces.css';
@value size11, size18 from "../../../../variables/sizes.css";

.badgeContainer {
  height: 100%;
  background-color: colorWhite;
  display: flex;
  flex-direction: column;
}

.spinnercontainer {
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.description {
  padding: space9;
}

.dashboardBadge {
  max-height: 80px;
  outline: none;
}
.sessionTitleHolder {
  background-color: colorMediumBlue;
  padding: space9;
  color: colorWhite;
  font-size: size18;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sessionTitleHolder h2 {
  font-size: size18;
  text-align: center;
}

.badgeNameHolder {
  text-align: center;
  font-size: 16px;
}

.badgeContentHolder {
  text-align: center;
  font-size: 14px;
}

.badgesGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.badge {
  text-align: center;
  align-items: center;
  margin: 0 auto;
}

.unearnedBadge {
  opacity: 0.3;
}

.allBadgesContainer {
  overflow: auto;
}
