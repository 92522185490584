@value space3, space18, space36, space72 from "./../../variables/spaces.css";
@value borderRadius, borderRadius6, size18 from "./../../variables/sizes.css";
@value colorWhite, colorDarkBlue, colorDarkGrey2, colorLightBlue2, colorYellow from "./../../variables/colors.css";

.holder {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  background-color: colorWhite;
  overflow: hidden;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

h2 {
  font-size: size18;
}

.titleHolder {
  flex: 0 0 auto;
  background-color: colorDarkBlue;
  color: colorWhite;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: space18;
}

.title {
  padding: space18;
  flex: 1 1 auto;
}

.contentHolder {
  flex: 1;
  width: 100%;
  min-height: 0px;
}

.wrapper {
  border-radius: borderRadius;
}

.spinnerHolder {
  text-align: center;
  padding: space36;
}

.moveInAnimation {
  animation-name: moveIn;
  animation-duration: 0.3s;
}

@keyframes moveIn {
  from {
    -webkit-transform: translate(1em, 0);
    -moz-transform: translate(1em, 0);
    -o-transform: translate(1em, 0);
    -ms-transform: translate(1em, 0);
    transform: translate(1em, 0);
  }
}
