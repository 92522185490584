@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorLightBlue,colorDarkBlue, colorMediumBlue, colorYellow from './../../variables/colors.css';
@value space9, space18 from './../../variables/spaces.css';
@value borderRadius, size11, size18 from "./../../variables/sizes.css";

.pageIcon {
  color: colorMediumBlue;
  border-radius: 50%;
  background-color: colorLightBlue;
  border: solid 1px colorLightBlue;
  cursor: pointer;
  font-size: 1.1rem;
  width: 1.5rem;
  height: 1.5rem;
}

.pageIcon:hover {
  background-color: colorMediumBlue;
  color: colorWhite;
}

.pageIcon:disabled {
  background-color: grey;
  color: lightgray;
}
