@value space3, space9, space15, space18, space36, space40, space72 from './../../../variables/spaces.css';
@value colorBlack, colorMediumBlue, colorDarkBlue, colorYellow from './../../../variables/colors.css';
@value borderSize, borderRadius, borderRadius6 from './../../../variables/sizes.css';

.step2Container {
  height: 72vh;
}

.step2Content {
  display: flex;
  flex-direction: column;
}

.messageInfoBox {
  display: flex;
  flex-direction: column;
  background-color: colorYellow;
  color: colorDarkBlue;
  font-weight: bold;
  padding: space9 space36;
}

.emailContainer {
  flex: 1;
  min-height: 0;
  border: borderSize solid colorBlack;
  border-radius: borderRadius;
  margin: space9 space18;
  overflow: hidden;
}

.actionButtonContainer {
  text-align: center;
  margin-bottom: space15;
}

.actionBtn {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.yellowTitleBg {
  background-color: colorYellow;
  color: colorDarkBlue;
  text-align: center;
  margin-left: space72;
  margin-right: space72;
  padding-top: space3;
  padding-bottom: space3;
  border-radius: borderRadius6;
}

/* Email content scroll */
.emailScroll {
  padding-top: space9;
  padding-bottom: space9;
  margin-left: space40;
  height: 100%;
}

.emailScroll a {
  color: colorMediumBlue;
}

.emailScroll::-webkit-scrollbar-thumb {
  background: colorDarkBlue;
  border-radius: borderRadius6;
}
