@value space3, space5, space10, space8, space18, space20, space200 from '../../../../variables/spaces.css';
@value colorRed from '../../../../variables/colors.css';

.iconButton {
  height: space20;
}

.displayEmployeesButton {
  float: right;
  margin: 0 space5;
}

.editRoleButton {
  float: right;
  margin: 0 space3;
}

.editRoleSelect {
  min-width: space200;
  margin-right: space3;
}

.editRoleMenu {
  font-weight: normal;
  display: inline-block;
  padding: space10 0 0 0;
}

.collapseContainer {
  clear: both;
}

.optionButtons {
  float: right;
}

.dateLabels {
  float: left;
}

.cardTitle {
  float: left;
}

.cardSubHead {
  float: left;
  font-weight: normal;
}

.confirmButtons {
  float: left;
  margin: space5;
}
.redBorder div {
  border-color: colorRed;
  border-width: thick;
}
