@value colorLightBlue, colorPink, colorDarkBlue, colorLightBlue2, colorYellow from './../../../variables/colors.css';
@value space4, space15, space25, space27, space33, space27, space60 from './../../../variables/spaces.css';
@value borderRadius6, size1, size5, size8, size9, size16, size18, size26, size52 from './../../../variables/sizes.css';

.officeFilter-holder__flex {
  margin-top: space27;
  height: space33;
  width: fit-content;
  padding: auto;
  background-color: white;
  color: colorDarkBlue;
  border: size1 solid colorDarkBlue;
  border-radius: size9 size9 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: size16;
}

.accessible-highlight:focus {
  outline: colorPink solid space4;
  outline-offset: -4px;
}

.divider {
  border: size1 solid colorDarkBlue;
  height: 100%;
}

.entry-button {
  padding: size5;
}

.entry-button:hover,
.entry-button--selected {
  background-color: colorYellow;
}

.highlighted {
  background-color: colorYellow;
}

.show-drop-down:hover > .drop-down,
.show-drop-down:focus-within > .drop-down {
  display: block;
}

@value menuItemHeight: size52;
@value menuItemMargin: size8;
@value panelWidth: 360px;
@value panelPadding: space4;
@value arrowPadding: size16;

.drop-down {
  display: none;
  position: absolute;
  top: space60;
  left: space15;
  z-index: 300;
  width: panelWidth;
  margin-top: -1px;
  border: size1 solid colorDarkBlue;
  border-radius: 0 0 0 size16;
  background-color: colorLightBlue2;
  padding: panelPadding panelPadding panelPadding panelPadding;
}

.menu-item {
  margin: menuItemMargin;
  height: menuItemHeight;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: arrowPadding;
  line-height: 1.2;
}

.menu-item__title {
  text-transform: uppercase;
}

.menu-item__leader {
  font-weight: bold;
}

.menu-item:hover,
.menu-item--selected {
  background-color: colorYellow;
  border: size1 solid colorDarkBlue;
  border-radius: size8;
}

.menu-item__select-box {
  flex: 0 0 auto;
  align-self: flex-start;
  margin: space4 0 0 space4;
  width: size18;
  height: size18;
  background-color: colorLightBlue;
  border: size1 solid colorDarkBlue;
  border-radius: borderRadius6;
}

.menu-item__select-box--selected {
  background-color: colorYellow;
}

.menu-item__content {
  text-align: left;
  margin: auto 0 auto size8;
  width: panelWidth;
}

.menu-item__hover {
  position: absolute;
  width: 100%;
  height: menuItemHeight;
}

.menu-item svg {
  width: size16;
  height: size16;
}

.menu-item:hover > svg {
  transform: rotate(-90deg);
}

.menu-item__reference {
  width: 0;
  height: 0;
  display: none;
}

.menu-item__reference--beside {
  position: relative;
  align-self: flex-start;
  left: calc(menuItemMargin + panelPadding + arrowPadding);
  top: -2px;
}

.menu-item__reference--top {
  position: absolute;
  top: -1px;
  right: 0;
}

.menu-item__container {
  position: absolute;
  /* 2px for border */
  top: 0;
  left: 0;
  /* top: calc((menuItemMargin + 2px) * -1);
  left: calc(panelPadding + menuItemMargin); */
  background-color: colorLightBlue2;
  border: size1 solid colorDarkBlue;
  padding: space4;
}

.menu-item:hover > .menu-item__reference,
.menu-item:focus-within > .menu-item__reference {
  display: block;
}

.menu-item:hover,
.menu-item:focus-within {
  display: flex;
}

.no-manage {
  margin: menuItemMargin;
}
