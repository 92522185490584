.roundButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 3px 8px grey;
}

.add {
  background-color: #007bff;
}

.add:hover {
  background-color: rgba(0, 123, 255, 0.85);
  box-shadow: 0 0 4px grey;
}

.add img {
  margin-top: 10px;
  width: 30px;
  height: 30px;
}

.confirm img {
  margin-top: 10px;
  width: 30px;
  height: 30px;
}

.confirm {
  /*background-color: #388E3C;*/
  background-color: #007bff;
}

.confirm:hover {
  background-color: #007bff;
  box-shadow: 0 0 4px grey;
}

.spinner {
  margin-top: 10px !important;
}
.courseInlineImage {
  height: 24px;
}

.courseInlineButton {
  float: right;
  margin: 0 2px;
}
