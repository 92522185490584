@value colorLightGrey, colorDarkBlue, colorYellow, colorBlack, colorWhite, colorBlue from "../../../../variables/colors.css";
@value space1, space2, space3, space5, space10, space20 space40, space50 from "../../../../variables/spaces.css";

/*CSS styles shared across Share It tabs*/
.adminFirstNav button {
  padding: 0.5rem 1rem;
  margin-top: 0.3rem;
}

.adminFirstNav .active {
  background-color: colorLightGrey;
}

.adminRoot {
  display: block;
  width: 1250px;
  margin-left: auto;
  margin-right: auto;
  background-color: colorWhite;
}

:global(.disabled:disabled) {
  background-color: colorLightGrey;
  color: colorDarkBlue;
}

:global(.nav-tabs .nav-link.disabled) {
  color: colorBlack;
}

:global(.form-control) {
  color: colorBlack;
}

:global(.tab-content) {
  margin: space10;
}

:global(.nav-link.active.disabled) {
  border: none;
  color: colorBlue;
  border-bottom: space2 solid colorBlue;
}

:global(.card) {
  margin: space10 0;
}

.selectPlaceholder {
  color: colorBlack;
}

.submitBtn {
  float: right;
  margin: space10 0;
  clear: both;
}

.submitBtnMargin28 {
  margin: space30 0 0 0;
}

.tabMargin {
  margin-top: space10;
}

.card {
  margin: space10 0;
}

.cardTitle {
  max-width: 80%;
}

.subText {
  font-style: italic;
  color: colorBlack;
  margin: auto 0;
  font-size: 12px;
}

.cardSubHead {
  float: left;
  font-weight: normal;
}

.hostshadowButton:disabled {
  font-weight: 400;
  border: none;
}

.hostshadowNavlink {
  padding: 0 0 10px 0;
  margin-left: space10;
  margin-right: space20;
}

.hostshadowTab {
  border-bottom: none;
}

.showHideButton {
  margin-left: 50%;
  border: none;
  padding-bottom: 2px;
}

.collapseShadowHost {
  margin: 0px space10 space5 space10;
}

.collapseShadowHost_column {
  padding: 0px 15px 0px 15px;
}

.collapseShadowHost_card {
  width: 100%;
  background: colorLightGrey;
  margin: 0px;
  border: none;
}

.collapseShadowHost_cardHeader {
  background-color: colorLightGrey;
  border: none;
  padding-bottom: 0px;
}

.collapseShadowHost_infoList {
  list-style-type: none;
  padding-left: 0px;
}

.collapseShadowHost_infoTable {
  margin: 0px space10 space5 space10;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: none;
  padding: 20px;
}

.collapseShadowHost_cardBold {
  font-weight: bold;
}

.shadowHostDeleteModal {
  padding-top: 0px;
  padding-bottom: 0px;
}

.coursedisplaycsvbutton {
  background-color: colorWhite;
  font-weight: bold;
  color: var(--color-dark-blue);
  border-style: solid;
  border-color: var(--color-dark-blue);
  float: right;
  /*Align the flexbox container havin*/
  align-self: center;
  margin-left: 1rem;
  height: 80%;
  /*Make the Export button a flexbox containing (image & text)*/
  display: flex;
  align-items: center;
}

.dots {
  margin-left: space3;
}

.multipleHostsCardHeader {
  margin-right: space40;
  margin-left: space50;
}

.removeButton {
  color: dodgerblue;
}

.overflowWrap {
  overflow-wrap: anywhere;
}
