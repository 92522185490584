@value colorMediumBlue, colorYellow, colorWhite, colorDarkBlue, colorLightBlue, colorGrey, colorLightGrey, colorPink from "./../../variables/colors.css";
@value space5, space9, space10, space18, space36 from "./../../variables/spaces.css";
@value borderSize, size15, size16, size18, size24 from "./../../variables/sizes.css";

.upcomingSessionsHolder {
  width: 1250px;
  margin: space18 auto;
}

.holiday,
.holiday:hover {
  padding: space18 space5 space18 space5;
  border-color: colorLightGrey;
  background-color: colorLightGrey;
  color: colorDarkBlue;
}

.holiday :global(.fc-content) {
  text-align: center;
  white-space: normal;
  font-size: 16px;
  font-weight: 500;
}

.upcomingSessionsHolder h1 {
  text-transform: uppercase;
  font-weight: 700;
}

.toggles {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calendarHolder {
  border-radius: space9;
  background-color: colorDarkBlue;
  padding: space18;
}

.calendarHolder > div {
  height: 100%;
}

.titleHolder {
  margin-bottom: space18;
}

.titleHolder h1 {
  flex: 1 0 auto;
  margin: 0;
  color: colorMediumBlue;
  font-size: size24;
}

.titleHolder a {
  flex: 0 0 auto;
  align-self: flex-end;
  font-size: size16;
  color: colorDarkBlue;
}

.UpcomingSessionsListButton {
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.close {
  margin: 100;
}

.closeIcon {
  background-color: colorLightGrey;
  border: solid 1px colorDarkBlue;
}

.closeIcon:hover {
  background-color: colorDarkBlue;
  color: white;
}

.searchBar {
  float: right;
}

.searchIcon {
  color: colorMediumBlue;
}

.searchButton {
  border-radius: 0 space5 space5 0;
  background-color: colorLightBlue;
  float: right;
  border: borderSize solid colorMediumBlue;
  border-left: 0;
}

.searchButton > span {
  padding: space5;
}

.inputField {
  width: 240px;
  border-radius: space5 0 0 space5;
  border: borderSize solid colorMediumBlue;
  padding: space5;
}

input[type='search']::-webkit-search-cancel-button {
  cursor: pointer;
}

.material > a {
  color: colorDarkBlue;
  font-size: size18;
}

.material > a:hover {
  color: colorDarkBlue;
  background-color: colorYellow;
  border-radius: space5 0 0 space5;
}

.material {
  margin-left: space36;
  font-size: size18;
  color: colorDarkBlue;
}

.material:hover {
  font-size: size18;
  color: colorDarkBlue;
  border-radius: 10px;
  background-color: colorYellow;
}

.materialHolder {
  max-width: 500px;
}

.materialHolder hr {
  background-color: colorLightGrey;
  size: 2px;
}

.materialContainer {
  margin-top: size16;
  margin-bottom: size16;
}

.filter {
  color: colorDarkBlue;
}

.filterImg {
  width: 25px;
  height: 12px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 0;
}

/* AODA purpose for switch */
.switch:focus-within {
  outline: none;
  box-shadow: 0 0 2px 2px colorPink;
}

.sliderText {
  padding-left: space5;
  padding-right: space5;
  color: colorDarkBlue;
  display: inline-block;
  margin-left: space18;
  margin-right: space18;
}

.sliderTextSelected {
  padding-left: space5;
  padding-right: space5;
  color: colorDarkBlue;
  display: inline-block;
  margin-left: space18;
  margin-right: space18;
  background-color: colorYellow;
  border: borderSize solid colorDarkBlue;
  border-radius: 10px;
}

.sliderTextSelected.managerSliderText {
  color: colorDarkBlue;
  background-color: colorLightBlue;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: colorWhite;
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: colorMediumBlue;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: colorWhite;
}

input:focus + .slider {
  box-shadow: 0 0 1px colorWhite;
}

.slider.checked:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.recommendDiv {
  flex-basis: 0;
  flex-grow: 1;
}
