@value colorMediumBlue, colorLightGrey, colorWhite, colorRed, colorDarkBlue, colorLightBlue,colorLightBlue2, colorDarkGrey2, colorYellow from "./../../variables/colors.css";
@value space5, space9, space15, space18, space36, space72 from "./../../variables/spaces.css";
@value borderRadius, borderSize, size16, size18, size24, maxLogoSize, minLogoSize from "./../../variables/sizes.css";

.rightP36 {
  padding-right: space36;
}

.courseContainer {
  display: flex;
  flex-direction: column;
  border-radius: borderRadius;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  background-color: colorWhite;
}

.coursePageBanner {
  object-fit: cover;
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1 72vh;
}

.courseRegisterButton {
  float: right;
  margin: 0 space18 space15 0;
}

.courseRegisterButton > * {
  margin-right: space18;
}

.courseSubtext {
  color: colorDarkBlue;
  margin-bottom: space5;
}

.courseSubtextOutstanding {
  color: colorRed;
  margin-bottom: space5;
}

.linkOutstanding {
  color: colorRed;
  margin-bottom: space5;
}

.linkOutstanding:hover {
  color: colorRed;
  margin-bottom: space5;
  text-decoration: underline;
}

.linkCompleted {
  color: colorDarkBlue;
  margin-bottom: space5;
}

.logoHolder {
  max-height: maxLogoSize;
  min-height: minLogoSize;
  min-width: minLogoSize;
  max-width: maxLogoSize;
  margin-right: space72;
}

.spinnerHolder {
  height: 72vh;
  padding-top: space72;
  padding-bottom: space72;
  text-align: center;
}

.logo {
  max-height: maxLogoSize;
  min-height: minLogoSize;
  min-width: minLogoSize;
  max-width: maxLogoSize;
}

.presenterContainer {
  margin: space15 0;
}

.sessionType {
  font-size: size18;
  color: colorDarkBlue;
}

.sessionTitle {
  padding: 15px space36 15px space36;
  font-size: size18;
  color: colorWhite;
  flex: 1 1 auto;
}

.closeButton {
  margin: space9;
}

.header {
  background-color: colorDarkBlue;
  color: colorWhite;
}

.headerContainer {
  background-color: colorDarkBlue;
  display: flex;
  flex-direction: row;
}

.pillarContainer {
  padding: space9 space36 space9 space36;
  font-size: size18;
  background-color: colorLightBlue2;
}

.courseInfoContainer {
  margin-left: 0;
  margin-right: 0;
  font-size: size18;

  padding: space15 space36 space15 space36;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sessionDesc {
  /* min-height: 120px; */
  max-height: 100%;
  overflow-y: auto;
  border: borderSize solid colorDarkGrey2;
  border-radius: 10px;
  padding: space5;
  flex: 1 0 120px;
  margin: 0 space36 space18 space36;
}

.presenterContainer {
  font-size: size18;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 space36 0 space36;
}

/* scrollBar */
.sessionDesc::-webkit-scrollbar {
  width: size16;
}

.sessionDesc::-webkit-scrollbar-track {
  border-left: solid 1px colorDarkGrey2;
}

.sessionDesc::-webkit-scrollbar-thumb {
  background: colorLightBlue2;
  border-radius: 10px;
}

.coursePageBanner a {
  color: colorDarkBlue;
}

.materialHolder {
  max-width: 500px;
}

.materialHolder hr {
  margin: space9 0 space9 0;
  background-color: colorLightGrey;
}

.material > a {
  color: colorDarkBlue;
  font-size: size18;
  border-radius: space5 0 0 space5;
  text-decoration: none;
}

.material > a:hover {
  color: colorDarkBlue;
  background-color: colorYellow;
  border-radius: space5 0 0 space5;
}

.material {
  padding-left: 10px;
  margin-left: space9;
  font-size: size18;
  color: colorDarkBlue;
}

.material:hover {
  color: colorDarkBlue;
  border-radius: 10px;
  background-color: colorYellow;
}

.highlightTransition {
  animation-name: highlight;
  animation-duration: 5s;
}

@keyframes highlight {
  from {
    background-color: colorYellow;
  }
  to {
    background-color: none;
  }
}
