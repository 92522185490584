/* Confetti */
[class|='IAconfetti'] {
  position: absolute;
}

.IAconfetti-0 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 91%;
  opacity: 0.7223672953;
  -webkit-transform: rotate(64.7946671158deg);
  transform: rotate(64.7946671158deg);
  -webkit-animation: drop-0 4.0524749965s 0.6086896114s infinite;
  animation: drop-0 4.0524749965s 0.6086896114s infinite;
}

@-webkit-keyframes drop-0 {
  100% {
    top: 110%;
    left: 100%;
  }
}

@keyframes drop-0 {
  100% {
    top: 110%;
    left: 100%;
  }
}
.IAconfetti-1 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 28%;
  opacity: 0.812253077;
  -webkit-transform: rotate(320.919047237deg);
  transform: rotate(320.919047237deg);
  -webkit-animation: drop-1 4.5021332954s 0.0611094332s infinite;
  animation: drop-1 4.5021332954s 0.0611094332s infinite;
}

@-webkit-keyframes drop-1 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-1 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.IAconfetti-2 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 19%;
  opacity: 1.4560685932;
  -webkit-transform: rotate(69.7066102369deg);
  transform: rotate(69.7066102369deg);
  -webkit-animation: drop-2 4.9876384634s 0.7877560963s infinite;
  animation: drop-2 4.9876384634s 0.7877560963s infinite;
}

@-webkit-keyframes drop-2 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-2 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.IAconfetti-3 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 36%;
  opacity: 1.0693125199;
  -webkit-transform: rotate(27.5579405323deg);
  transform: rotate(27.5579405323deg);
  -webkit-animation: drop-3 4.8807395546s 0.9396261775s infinite;
  animation: drop-3 4.8807395546s 0.9396261775s infinite;
}

@-webkit-keyframes drop-3 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-3 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.IAconfetti-4 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 8%;
  opacity: 0.5210011645;
  -webkit-transform: rotate(17.8841593258deg);
  transform: rotate(17.8841593258deg);
  -webkit-animation: drop-4 4.5708013747s 0.1885891365s infinite;
  animation: drop-4 4.5708013747s 0.1885891365s infinite;
}

@-webkit-keyframes drop-4 {
  100% {
    top: 110%;
    left: 17%;
  }
}

@keyframes drop-4 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.IAconfetti-5 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 28%;
  opacity: 1.179664369;
  -webkit-transform: rotate(334.7258070309deg);
  transform: rotate(334.7258070309deg);
  -webkit-animation: drop-5 4.0517090833s 0.8006296926s infinite;
  animation: drop-5 4.0517090833s 0.8006296926s infinite;
}

@-webkit-keyframes drop-5 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-5 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.IAconfetti-6 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 12%;
  opacity: 1.3533658211;
  -webkit-transform: rotate(154.8485258325deg);
  transform: rotate(154.8485258325deg);
  -webkit-animation: drop-6 4.4867882143s 0.6805206242s infinite;
  animation: drop-6 4.4867882143s 0.6805206242s infinite;
}

@-webkit-keyframes drop-6 {
  100% {
    top: 110%;
    left: 19%;
  }
}

@keyframes drop-6 {
  100% {
    top: 110%;
    left: 19%;
  }
}
.IAconfetti-7 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 23%;
  opacity: 1.4496470403;
  -webkit-transform: rotate(309.1481698547deg);
  transform: rotate(309.1481698547deg);
  -webkit-animation: drop-7 4.6370439911s 0.1405061783s infinite;
  animation: drop-7 4.6370439911s 0.1405061783s infinite;
}

@-webkit-keyframes drop-7 {
  100% {
    top: 110%;
    left: 38%;
  }
}

@keyframes drop-7 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.IAconfetti-8 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 21%;
  opacity: 0.6997969508;
  -webkit-transform: rotate(90.7567189826deg);
  transform: rotate(90.7567189826deg);
  -webkit-animation: drop-8 4.0084124109s 0.4502468256s infinite;
  animation: drop-8 4.0084124109s 0.4502468256s infinite;
}

@-webkit-keyframes drop-8 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-8 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.IAconfetti-9 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 47%;
  opacity: 1.2171387847;
  -webkit-transform: rotate(10.6686421792deg);
  transform: rotate(10.6686421792deg);
  -webkit-animation: drop-9 4.8917910818s 0.4392759465s infinite;
  animation: drop-9 4.8917910818s 0.4392759465s infinite;
}

@-webkit-keyframes drop-9 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-9 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.IAconfetti-10 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 75%;
  opacity: 1.2690457749;
  -webkit-transform: rotate(359.0665831331deg);
  transform: rotate(359.0665831331deg);
  -webkit-animation: drop-10 4.8591694226s 0.3715782424s infinite;
  animation: drop-10 4.8591694226s 0.3715782424s infinite;
}

@-webkit-keyframes drop-10 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-10 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.IAconfetti-11 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 8%;
  opacity: 0.6184489919;
  -webkit-transform: rotate(139.5444563811deg);
  transform: rotate(139.5444563811deg);
  -webkit-animation: drop-11 4.5485675509s 0.6037940069s infinite;
  animation: drop-11 4.5485675509s 0.6037940069s infinite;
}

@-webkit-keyframes drop-11 {
  100% {
    top: 110%;
    left: 16%;
  }
}

@keyframes drop-11 {
  100% {
    top: 110%;
    left: 16%;
  }
}
.IAconfetti-12 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 98%;
  opacity: 0.560050771;
  -webkit-transform: rotate(215.9613332388deg);
  transform: rotate(215.9613332388deg);
  -webkit-animation: drop-12 4.0224283678s 0.8735271557s infinite;
  animation: drop-12 4.0224283678s 0.8735271557s infinite;
}

@-webkit-keyframes drop-12 {
  100% {
    top: 110%;
    left: 108%;
  }
}

@keyframes drop-12 {
  100% {
    top: 110%;
    left: 108%;
  }
}
.IAconfetti-13 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 77%;
  opacity: 1.0034968118;
  -webkit-transform: rotate(74.7733155817deg);
  transform: rotate(74.7733155817deg);
  -webkit-animation: drop-13 4.647918607s 0.3844794023s infinite;
  animation: drop-13 4.647918607s 0.3844794023s infinite;
}

@-webkit-keyframes drop-13 {
  100% {
    top: 110%;
    left: 81%;
  }
}

@keyframes drop-13 {
  100% {
    top: 110%;
    left: 81%;
  }
}
.IAconfetti-14 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 39%;
  opacity: 1.2084879659;
  -webkit-transform: rotate(343.7073536963deg);
  transform: rotate(343.7073536963deg);
  -webkit-animation: drop-14 4.9904807783s 0.3842651462s infinite;
  animation: drop-14 4.9904807783s 0.3842651462s infinite;
}

@-webkit-keyframes drop-14 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-14 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.IAconfetti-15 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 22%;
  opacity: 0.6000613696;
  -webkit-transform: rotate(0.8661666489deg);
  transform: rotate(0.8661666489deg);
  -webkit-animation: drop-15 4.1254365379s 0.5274223307s infinite;
  animation: drop-15 4.1254365379s 0.5274223307s infinite;
}

@-webkit-keyframes drop-15 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-15 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.IAconfetti-16 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 53%;
  opacity: 1.495985955;
  -webkit-transform: rotate(294.3671502297deg);
  transform: rotate(294.3671502297deg);
  -webkit-animation: drop-16 4.8737533105s 0.2068316278s infinite;
  animation: drop-16 4.8737533105s 0.2068316278s infinite;
}

@-webkit-keyframes drop-16 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-16 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.IAconfetti-17 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 6%;
  opacity: 1.4506901173;
  -webkit-transform: rotate(28.7404266924deg);
  transform: rotate(28.7404266924deg);
  -webkit-animation: drop-17 4.2799954511s 0.6232701454s infinite;
  animation: drop-17 4.2799954511s 0.6232701454s infinite;
}

@-webkit-keyframes drop-17 {
  100% {
    top: 110%;
    left: 16%;
  }
}

@keyframes drop-17 {
  100% {
    top: 110%;
    left: 16%;
  }
}
.IAconfetti-18 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 16%;
  opacity: 0.8316422153;
  -webkit-transform: rotate(337.4837656395deg);
  transform: rotate(337.4837656395deg);
  -webkit-animation: drop-18 4.9687788755s 0.065570907s infinite;
  animation: drop-18 4.9687788755s 0.065570907s infinite;
}

@-webkit-keyframes drop-18 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-18 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.IAconfetti-19 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 94%;
  opacity: 0.9711579055;
  -webkit-transform: rotate(115.9152044711deg);
  transform: rotate(115.9152044711deg);
  -webkit-animation: drop-19 4.8230991779s 0.5251551712s infinite;
  animation: drop-19 4.8230991779s 0.5251551712s infinite;
}

@-webkit-keyframes drop-19 {
  100% {
    top: 110%;
    left: 109%;
  }
}

@keyframes drop-19 {
  100% {
    top: 110%;
    left: 109%;
  }
}
.IAconfetti-20 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 24%;
  opacity: 1.3290441746;
  -webkit-transform: rotate(284.9949612316deg);
  transform: rotate(284.9949612316deg);
  -webkit-animation: drop-20 4.7811576315s 0.5631694415s infinite;
  animation: drop-20 4.7811576315s 0.5631694415s infinite;
}

@-webkit-keyframes drop-20 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-20 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.IAconfetti-21 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 69%;
  opacity: 0.5235731493;
  -webkit-transform: rotate(142.4078523141deg);
  transform: rotate(142.4078523141deg);
  -webkit-animation: drop-21 4.8978780616s 0.7849312791s infinite;
  animation: drop-21 4.8978780616s 0.7849312791s infinite;
}

@-webkit-keyframes drop-21 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-21 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.IAconfetti-22 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 85%;
  opacity: 0.7846342035;
  -webkit-transform: rotate(110.7528447877deg);
  transform: rotate(110.7528447877deg);
  -webkit-animation: drop-22 4.6575960427s 0.9168780972s infinite;
  animation: drop-22 4.6575960427s 0.9168780972s infinite;
}

@-webkit-keyframes drop-22 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-22 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.IAconfetti-23 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 59%;
  opacity: 0.7796892685;
  -webkit-transform: rotate(247.5798968564deg);
  transform: rotate(247.5798968564deg);
  -webkit-animation: drop-23 4.5190505467s 0.8545917302s infinite;
  animation: drop-23 4.5190505467s 0.8545917302s infinite;
}

@-webkit-keyframes drop-23 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-23 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.IAconfetti-24 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 13%;
  opacity: 1.3752238337;
  -webkit-transform: rotate(217.3897768328deg);
  transform: rotate(217.3897768328deg);
  -webkit-animation: drop-24 4.8982975982s 0.9250055513s infinite;
  animation: drop-24 4.8982975982s 0.9250055513s infinite;
}

@-webkit-keyframes drop-24 {
  100% {
    top: 110%;
    left: 17%;
  }
}

@keyframes drop-24 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.IAconfetti-25 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 68%;
  opacity: 1.0879412465;
  -webkit-transform: rotate(260.4660017539deg);
  transform: rotate(260.4660017539deg);
  -webkit-animation: drop-25 4.573265281s 0.0578239186s infinite;
  animation: drop-25 4.573265281s 0.0578239186s infinite;
}

@-webkit-keyframes drop-25 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-25 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.IAconfetti-26 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 91%;
  opacity: 1.0007825292;
  -webkit-transform: rotate(329.6704161259deg);
  transform: rotate(329.6704161259deg);
  -webkit-animation: drop-26 4.4532103649s 0.0044189224s infinite;
  animation: drop-26 4.4532103649s 0.0044189224s infinite;
}

@-webkit-keyframes drop-26 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-26 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.IAconfetti-27 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 83%;
  opacity: 0.8643406553;
  -webkit-transform: rotate(308.8008806173deg);
  transform: rotate(308.8008806173deg);
  -webkit-animation: drop-27 4.2628541146s 0.9953356762s infinite;
  animation: drop-27 4.2628541146s 0.9953356762s infinite;
}

@-webkit-keyframes drop-27 {
  100% {
    top: 110%;
    left: 96%;
  }
}

@keyframes drop-27 {
  100% {
    top: 110%;
    left: 96%;
  }
}
.IAconfetti-28 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 30%;
  opacity: 0.6587895608;
  -webkit-transform: rotate(317.3091465526deg);
  transform: rotate(317.3091465526deg);
  -webkit-animation: drop-28 4.6152841915s 0.6363337267s infinite;
  animation: drop-28 4.6152841915s 0.6363337267s infinite;
}

@-webkit-keyframes drop-28 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-28 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.IAconfetti-29 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 15%;
  opacity: 1.3526726957;
  -webkit-transform: rotate(107.6126674307deg);
  transform: rotate(107.6126674307deg);
  -webkit-animation: drop-29 4.8602316383s 0.5753063337s infinite;
  animation: drop-29 4.8602316383s 0.5753063337s infinite;
}

@-webkit-keyframes drop-29 {
  100% {
    top: 110%;
    left: 30%;
  }
}

@keyframes drop-29 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.IAconfetti-30 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 85%;
  opacity: 1.2589680851;
  -webkit-transform: rotate(234.2376037942deg);
  transform: rotate(234.2376037942deg);
  -webkit-animation: drop-30 4.9012175088s 0.886336157s infinite;
  animation: drop-30 4.9012175088s 0.886336157s infinite;
}

@-webkit-keyframes drop-30 {
  100% {
    top: 110%;
    left: 94%;
  }
}

@keyframes drop-30 {
  100% {
    top: 110%;
    left: 94%;
  }
}
.IAconfetti-31 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 46%;
  opacity: 1.4967512238;
  -webkit-transform: rotate(150.0650548136deg);
  transform: rotate(150.0650548136deg);
  -webkit-animation: drop-31 4.741672349s 0.5309290956s infinite;
  animation: drop-31 4.741672349s 0.5309290956s infinite;
}

@-webkit-keyframes drop-31 {
  100% {
    top: 110%;
    left: 55%;
  }
}

@keyframes drop-31 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.IAconfetti-32 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 89%;
  opacity: 0.56527286;
  -webkit-transform: rotate(168.0874299258deg);
  transform: rotate(168.0874299258deg);
  -webkit-animation: drop-32 4.4755145071s 0.5706410288s infinite;
  animation: drop-32 4.4755145071s 0.5706410288s infinite;
}

@-webkit-keyframes drop-32 {
  100% {
    top: 110%;
    left: 103%;
  }
}

@keyframes drop-32 {
  100% {
    top: 110%;
    left: 103%;
  }
}
.IAconfetti-33 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 53%;
  opacity: 0.9842441435;
  -webkit-transform: rotate(58.8678676501deg);
  transform: rotate(58.8678676501deg);
  -webkit-animation: drop-33 4.1574698075s 0.8647741768s infinite;
  animation: drop-33 4.1574698075s 0.8647741768s infinite;
}

@-webkit-keyframes drop-33 {
  100% {
    top: 110%;
    left: 54%;
  }
}

@keyframes drop-33 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.IAconfetti-34 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 93%;
  opacity: 1.4108030696;
  -webkit-transform: rotate(103.3317914663deg);
  transform: rotate(103.3317914663deg);
  -webkit-animation: drop-34 4.4022464229s 0.7025676249s infinite;
  animation: drop-34 4.4022464229s 0.7025676249s infinite;
}

@-webkit-keyframes drop-34 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-34 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.IAconfetti-35 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 25%;
  opacity: 1.1195639025;
  -webkit-transform: rotate(264.2706805086deg);
  transform: rotate(264.2706805086deg);
  -webkit-animation: drop-35 4.4349154491s 0.6467342411s infinite;
  animation: drop-35 4.4349154491s 0.6467342411s infinite;
}

@-webkit-keyframes drop-35 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-35 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.IAconfetti-36 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 71%;
  opacity: 1.2262614542;
  -webkit-transform: rotate(217.7544515012deg);
  transform: rotate(217.7544515012deg);
  -webkit-animation: drop-36 4.8298733568s 0.236542282s infinite;
  animation: drop-36 4.8298733568s 0.236542282s infinite;
}

@-webkit-keyframes drop-36 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-36 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.IAconfetti-37 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 51%;
  opacity: 1.3723851134;
  -webkit-transform: rotate(180.4604840849deg);
  transform: rotate(180.4604840849deg);
  -webkit-animation: drop-37 4.8111826504s 0.8589817571s infinite;
  animation: drop-37 4.8111826504s 0.8589817571s infinite;
}

@-webkit-keyframes drop-37 {
  100% {
    top: 110%;
    left: 60%;
  }
}

@keyframes drop-37 {
  100% {
    top: 110%;
    left: 60%;
  }
}
.IAconfetti-38 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 100%;
  opacity: 1.0694197733;
  -webkit-transform: rotate(177.8004251794deg);
  transform: rotate(177.8004251794deg);
  -webkit-animation: drop-38 4.290450679s 0.8757296246s infinite;
  animation: drop-38 4.290450679s 0.8757296246s infinite;
}

@-webkit-keyframes drop-38 {
  100% {
    top: 110%;
    left: 107%;
  }
}

@keyframes drop-38 {
  100% {
    top: 110%;
    left: 107%;
  }
}
.IAconfetti-39 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 28%;
  opacity: 0.5876130278;
  -webkit-transform: rotate(192.1081942182deg);
  transform: rotate(192.1081942182deg);
  -webkit-animation: drop-39 4.9396264399s 0.4566925279s infinite;
  animation: drop-39 4.9396264399s 0.4566925279s infinite;
}

@-webkit-keyframes drop-39 {
  100% {
    top: 110%;
    left: 42%;
  }
}

@keyframes drop-39 {
  100% {
    top: 110%;
    left: 42%;
  }
}
.IAconfetti-40 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 94%;
  opacity: 1.3853213224;
  -webkit-transform: rotate(136.474034732deg);
  transform: rotate(136.474034732deg);
  -webkit-animation: drop-40 4.9019434128s 0.6347984902s infinite;
  animation: drop-40 4.9019434128s 0.6347984902s infinite;
}

@-webkit-keyframes drop-40 {
  100% {
    top: 110%;
    left: 105%;
  }
}

@keyframes drop-40 {
  100% {
    top: 110%;
    left: 105%;
  }
}
.IAconfetti-41 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 41%;
  opacity: 1.271124702;
  -webkit-transform: rotate(127.8984939112deg);
  transform: rotate(127.8984939112deg);
  -webkit-animation: drop-41 4.676404069s 0.1642277399s infinite;
  animation: drop-41 4.676404069s 0.1642277399s infinite;
}

@-webkit-keyframes drop-41 {
  100% {
    top: 110%;
    left: 44%;
  }
}

@keyframes drop-41 {
  100% {
    top: 110%;
    left: 44%;
  }
}
.IAconfetti-42 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 99%;
  opacity: 1.21787149;
  -webkit-transform: rotate(130.6888001277deg);
  transform: rotate(130.6888001277deg);
  -webkit-animation: drop-42 4.9134370181s 0.5189202084s infinite;
  animation: drop-42 4.9134370181s 0.5189202084s infinite;
}

@-webkit-keyframes drop-42 {
  100% {
    top: 110%;
    left: 111%;
  }
}

@keyframes drop-42 {
  100% {
    top: 110%;
    left: 111%;
  }
}
.IAconfetti-43 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 84%;
  opacity: 0.8892585088;
  -webkit-transform: rotate(158.5315830865deg);
  transform: rotate(158.5315830865deg);
  -webkit-animation: drop-43 4.7403485049s 0.5884428239s infinite;
  animation: drop-43 4.7403485049s 0.5884428239s infinite;
}

@-webkit-keyframes drop-43 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-43 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.IAconfetti-44 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 63%;
  opacity: 1.1460257495;
  -webkit-transform: rotate(50.4325611173deg);
  transform: rotate(50.4325611173deg);
  -webkit-animation: drop-44 4.8233644106s 0.2246440471s infinite;
  animation: drop-44 4.8233644106s 0.2246440471s infinite;
}

@-webkit-keyframes drop-44 {
  100% {
    top: 110%;
    left: 75%;
  }
}

@keyframes drop-44 {
  100% {
    top: 110%;
    left: 75%;
  }
}
.IAconfetti-45 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 55%;
  opacity: 1.0853100924;
  -webkit-transform: rotate(190.1975268376deg);
  transform: rotate(190.1975268376deg);
  -webkit-animation: drop-45 4.818480126s 0.4398580083s infinite;
  animation: drop-45 4.818480126s 0.4398580083s infinite;
}

@-webkit-keyframes drop-45 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-45 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.IAconfetti-46 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 49%;
  opacity: 1.248047778;
  -webkit-transform: rotate(78.9172969363deg);
  transform: rotate(78.9172969363deg);
  -webkit-animation: drop-46 4.5922045348s 0.0364876193s infinite;
  animation: drop-46 4.5922045348s 0.0364876193s infinite;
}

@-webkit-keyframes drop-46 {
  100% {
    top: 110%;
    left: 54%;
  }
}

@keyframes drop-46 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.IAconfetti-47 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 60%;
  opacity: 0.8093509433;
  -webkit-transform: rotate(223.1534878668deg);
  transform: rotate(223.1534878668deg);
  -webkit-animation: drop-47 4.6673830852s 0.0606419935s infinite;
  animation: drop-47 4.6673830852s 0.0606419935s infinite;
}

@-webkit-keyframes drop-47 {
  100% {
    top: 110%;
    left: 64%;
  }
}

@keyframes drop-47 {
  100% {
    top: 110%;
    left: 64%;
  }
}
.IAconfetti-48 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 58%;
  opacity: 1.2114167695;
  -webkit-transform: rotate(114.1957644959deg);
  transform: rotate(114.1957644959deg);
  -webkit-animation: drop-48 4.9316091489s 0.8927163863s infinite;
  animation: drop-48 4.9316091489s 0.8927163863s infinite;
}

@-webkit-keyframes drop-48 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-48 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.IAconfetti-49 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 18%;
  opacity: 1.4428131246;
  -webkit-transform: rotate(16.5221494591deg);
  transform: rotate(16.5221494591deg);
  -webkit-animation: drop-49 4.5419529292s 0.5311820249s infinite;
  animation: drop-49 4.5419529292s 0.5311820249s infinite;
}

@-webkit-keyframes drop-49 {
  100% {
    top: 110%;
    left: 27%;
  }
}

@keyframes drop-49 {
  100% {
    top: 110%;
    left: 27%;
  }
}
.IAconfetti-50 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 83%;
  opacity: 0.6943041204;
  -webkit-transform: rotate(36.8843024481deg);
  transform: rotate(36.8843024481deg);
  -webkit-animation: drop-50 4.0711587532s 0.1259362349s infinite;
  animation: drop-50 4.0711587532s 0.1259362349s infinite;
}

@-webkit-keyframes drop-50 {
  100% {
    top: 110%;
    left: 93%;
  }
}

@keyframes drop-50 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.IAconfetti-51 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 43%;
  opacity: 0.8064661604;
  -webkit-transform: rotate(68.3236874657deg);
  transform: rotate(68.3236874657deg);
  -webkit-animation: drop-51 4.4398289304s 0.1678564573s infinite;
  animation: drop-51 4.4398289304s 0.1678564573s infinite;
}

@-webkit-keyframes drop-51 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-51 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.IAconfetti-52 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 70%;
  opacity: 0.7037902398;
  -webkit-transform: rotate(281.1500463955deg);
  transform: rotate(281.1500463955deg);
  -webkit-animation: drop-52 4.6752465702s 0.0099245776s infinite;
  animation: drop-52 4.6752465702s 0.0099245776s infinite;
}

@-webkit-keyframes drop-52 {
  100% {
    top: 110%;
    left: 79%;
  }
}

@keyframes drop-52 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.IAconfetti-53 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 19%;
  opacity: 1.3553704925;
  -webkit-transform: rotate(190.3266935252deg);
  transform: rotate(190.3266935252deg);
  -webkit-animation: drop-53 4.0305214713s 0.7815077282s infinite;
  animation: drop-53 4.0305214713s 0.7815077282s infinite;
}

@-webkit-keyframes drop-53 {
  100% {
    top: 110%;
    left: 20%;
  }
}

@keyframes drop-53 {
  100% {
    top: 110%;
    left: 20%;
  }
}
.IAconfetti-54 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 87%;
  opacity: 0.6080669534;
  -webkit-transform: rotate(166.1720334305deg);
  transform: rotate(166.1720334305deg);
  -webkit-animation: drop-54 4.9602381099s 0.4318618736s infinite;
  animation: drop-54 4.9602381099s 0.4318618736s infinite;
}

@-webkit-keyframes drop-54 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-54 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.IAconfetti-55 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 42%;
  opacity: 1.3571834664;
  -webkit-transform: rotate(318.6098966283deg);
  transform: rotate(318.6098966283deg);
  -webkit-animation: drop-55 4.3801139164s 0.0274234834s infinite;
  animation: drop-55 4.3801139164s 0.0274234834s infinite;
}

@-webkit-keyframes drop-55 {
  100% {
    top: 110%;
    left: 52%;
  }
}

@keyframes drop-55 {
  100% {
    top: 110%;
    left: 52%;
  }
}
.IAconfetti-56 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 18%;
  opacity: 0.709977787;
  -webkit-transform: rotate(210.1906986387deg);
  transform: rotate(210.1906986387deg);
  -webkit-animation: drop-56 4.3039616566s 0.0174553122s infinite;
  animation: drop-56 4.3039616566s 0.0174553122s infinite;
}

@-webkit-keyframes drop-56 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-56 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.IAconfetti-57 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 63%;
  opacity: 0.7165496152;
  -webkit-transform: rotate(66.3711759831deg);
  transform: rotate(66.3711759831deg);
  -webkit-animation: drop-57 4.3972493265s 0.7791056253s infinite;
  animation: drop-57 4.3972493265s 0.7791056253s infinite;
}

@-webkit-keyframes drop-57 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-57 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.IAconfetti-58 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 88%;
  opacity: 1.0372995707;
  -webkit-transform: rotate(155.7853881498deg);
  transform: rotate(155.7853881498deg);
  -webkit-animation: drop-58 4.8250978016s 0.8761338061s infinite;
  animation: drop-58 4.8250978016s 0.8761338061s infinite;
}

@-webkit-keyframes drop-58 {
  100% {
    top: 110%;
    left: 96%;
  }
}

@keyframes drop-58 {
  100% {
    top: 110%;
    left: 96%;
  }
}
.IAconfetti-59 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 38%;
  opacity: 1.0536157006;
  -webkit-transform: rotate(187.498582131deg);
  transform: rotate(187.498582131deg);
  -webkit-animation: drop-59 4.3109738937s 0.6674397272s infinite;
  animation: drop-59 4.3109738937s 0.6674397272s infinite;
}

@-webkit-keyframes drop-59 {
  100% {
    top: 110%;
    left: 40%;
  }
}

@keyframes drop-59 {
  100% {
    top: 110%;
    left: 40%;
  }
}
.IAconfetti-60 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 6%;
  opacity: 0.8701942616;
  -webkit-transform: rotate(259.5818978081deg);
  transform: rotate(259.5818978081deg);
  -webkit-animation: drop-60 4.359519179s 0.3227766642s infinite;
  animation: drop-60 4.359519179s 0.3227766642s infinite;
}

@-webkit-keyframes drop-60 {
  100% {
    top: 110%;
    left: 17%;
  }
}

@keyframes drop-60 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.IAconfetti-61 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 2%;
  opacity: 1.2805240617;
  -webkit-transform: rotate(239.7930207969deg);
  transform: rotate(239.7930207969deg);
  -webkit-animation: drop-61 4.2923401221s 0.0555927573s infinite;
  animation: drop-61 4.2923401221s 0.0555927573s infinite;
}

@-webkit-keyframes drop-61 {
  100% {
    top: 110%;
    left: 3%;
  }
}

@keyframes drop-61 {
  100% {
    top: 110%;
    left: 3%;
  }
}
.IAconfetti-62 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 13%;
  opacity: 0.833503581;
  -webkit-transform: rotate(98.2183453124deg);
  transform: rotate(98.2183453124deg);
  -webkit-animation: drop-62 4.9157773395s 0.6785255049s infinite;
  animation: drop-62 4.9157773395s 0.6785255049s infinite;
}

@-webkit-keyframes drop-62 {
  100% {
    top: 110%;
    left: 20%;
  }
}

@keyframes drop-62 {
  100% {
    top: 110%;
    left: 20%;
  }
}
.IAconfetti-63 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 17%;
  opacity: 1.4079738759;
  -webkit-transform: rotate(319.6982870596deg);
  transform: rotate(319.6982870596deg);
  -webkit-animation: drop-63 4.2154211447s 0.4847303751s infinite;
  animation: drop-63 4.2154211447s 0.4847303751s infinite;
}

@-webkit-keyframes drop-63 {
  100% {
    top: 110%;
    left: 19%;
  }
}

@keyframes drop-63 {
  100% {
    top: 110%;
    left: 19%;
  }
}
.IAconfetti-64 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 52%;
  opacity: 0.5221338922;
  -webkit-transform: rotate(141.0709774988deg);
  transform: rotate(141.0709774988deg);
  -webkit-animation: drop-64 4.302874376s 0.4358418925s infinite;
  animation: drop-64 4.302874376s 0.4358418925s infinite;
}

@-webkit-keyframes drop-64 {
  100% {
    top: 110%;
    left: 66%;
  }
}

@keyframes drop-64 {
  100% {
    top: 110%;
    left: 66%;
  }
}
.IAconfetti-65 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 46%;
  opacity: 0.8334221986;
  -webkit-transform: rotate(98.4000326096deg);
  transform: rotate(98.4000326096deg);
  -webkit-animation: drop-65 4.1486814609s 0.9557669286s infinite;
  animation: drop-65 4.1486814609s 0.9557669286s infinite;
}

@-webkit-keyframes drop-65 {
  100% {
    top: 110%;
    left: 57%;
  }
}

@keyframes drop-65 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.IAconfetti-66 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 58%;
  opacity: 1.077389589;
  -webkit-transform: rotate(243.9540370573deg);
  transform: rotate(243.9540370573deg);
  -webkit-animation: drop-66 4.3781828002s 0.5772840762s infinite;
  animation: drop-66 4.3781828002s 0.5772840762s infinite;
}

@-webkit-keyframes drop-66 {
  100% {
    top: 110%;
    left: 59%;
  }
}

@keyframes drop-66 {
  100% {
    top: 110%;
    left: 59%;
  }
}
.IAconfetti-67 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 5%;
  opacity: 0.7143573582;
  -webkit-transform: rotate(291.7436123619deg);
  transform: rotate(291.7436123619deg);
  -webkit-animation: drop-67 4.7003332034s 0.7741233732s infinite;
  animation: drop-67 4.7003332034s 0.7741233732s infinite;
}

@-webkit-keyframes drop-67 {
  100% {
    top: 110%;
    left: 14%;
  }
}

@keyframes drop-67 {
  100% {
    top: 110%;
    left: 14%;
  }
}
.IAconfetti-68 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 36%;
  opacity: 1.3311698789;
  -webkit-transform: rotate(22.1884089258deg);
  transform: rotate(22.1884089258deg);
  -webkit-animation: drop-68 4.2505375892s 0.2847109629s infinite;
  animation: drop-68 4.2505375892s 0.2847109629s infinite;
}

@-webkit-keyframes drop-68 {
  100% {
    top: 110%;
    left: 49%;
  }
}

@keyframes drop-68 {
  100% {
    top: 110%;
    left: 49%;
  }
}
.IAconfetti-69 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 38%;
  opacity: 1.0720206948;
  -webkit-transform: rotate(92.8958970118deg);
  transform: rotate(92.8958970118deg);
  -webkit-animation: drop-69 4.4333527347s 0.4242539544s infinite;
  animation: drop-69 4.4333527347s 0.4242539544s infinite;
}

@-webkit-keyframes drop-69 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-69 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.IAconfetti-70 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 89%;
  opacity: 1.2665554243;
  -webkit-transform: rotate(124.0523203498deg);
  transform: rotate(124.0523203498deg);
  -webkit-animation: drop-70 4.9691445127s 0.8606096279s infinite;
  animation: drop-70 4.9691445127s 0.8606096279s infinite;
}

@-webkit-keyframes drop-70 {
  100% {
    top: 110%;
    left: 96%;
  }
}

@keyframes drop-70 {
  100% {
    top: 110%;
    left: 96%;
  }
}
.IAconfetti-71 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 72%;
  opacity: 1.273186224;
  -webkit-transform: rotate(207.9045304394deg);
  transform: rotate(207.9045304394deg);
  -webkit-animation: drop-71 4.2718164572s 0.9493017405s infinite;
  animation: drop-71 4.2718164572s 0.9493017405s infinite;
}

@-webkit-keyframes drop-71 {
  100% {
    top: 110%;
    left: 86%;
  }
}

@keyframes drop-71 {
  100% {
    top: 110%;
    left: 86%;
  }
}
.IAconfetti-72 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 43%;
  opacity: 0.805908115;
  -webkit-transform: rotate(337.8366587399deg);
  transform: rotate(337.8366587399deg);
  -webkit-animation: drop-72 4.1188719129s 0.4201438884s infinite;
  animation: drop-72 4.1188719129s 0.4201438884s infinite;
}

@-webkit-keyframes drop-72 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-72 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.IAconfetti-73 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 14%;
  opacity: 1.288100943;
  -webkit-transform: rotate(170.9804413679deg);
  transform: rotate(170.9804413679deg);
  -webkit-animation: drop-73 4.2274304274s 0.4733406573s infinite;
  animation: drop-73 4.2274304274s 0.4733406573s infinite;
}

@-webkit-keyframes drop-73 {
  100% {
    top: 110%;
    left: 16%;
  }
}

@keyframes drop-73 {
  100% {
    top: 110%;
    left: 16%;
  }
}
.IAconfetti-74 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 18%;
  opacity: 0.5295333144;
  -webkit-transform: rotate(134.2860141828deg);
  transform: rotate(134.2860141828deg);
  -webkit-animation: drop-74 4.4602254841s 0.9641002094s infinite;
  animation: drop-74 4.4602254841s 0.9641002094s infinite;
}

@-webkit-keyframes drop-74 {
  100% {
    top: 110%;
    left: 23%;
  }
}

@keyframes drop-74 {
  100% {
    top: 110%;
    left: 23%;
  }
}
.IAconfetti-75 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 40%;
  opacity: 0.5404493896;
  -webkit-transform: rotate(170.6081481629deg);
  transform: rotate(170.6081481629deg);
  -webkit-animation: drop-75 4.1438568845s 0.2495765653s infinite;
  animation: drop-75 4.1438568845s 0.2495765653s infinite;
}

@-webkit-keyframes drop-75 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-75 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.IAconfetti-76 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 31%;
  opacity: 0.7062871608;
  -webkit-transform: rotate(265.8202377119deg);
  transform: rotate(265.8202377119deg);
  -webkit-animation: drop-76 4.1884260706s 0.9566227854s infinite;
  animation: drop-76 4.1884260706s 0.9566227854s infinite;
}

@-webkit-keyframes drop-76 {
  100% {
    top: 110%;
    left: 37%;
  }
}

@keyframes drop-76 {
  100% {
    top: 110%;
    left: 37%;
  }
}
.IAconfetti-77 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 96%;
  opacity: 0.8322133134;
  -webkit-transform: rotate(93.5765596977deg);
  transform: rotate(93.5765596977deg);
  -webkit-animation: drop-77 4.7209346607s 0.9783809832s infinite;
  animation: drop-77 4.7209346607s 0.9783809832s infinite;
}

@-webkit-keyframes drop-77 {
  100% {
    top: 110%;
    left: 97%;
  }
}

@keyframes drop-77 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.IAconfetti-78 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 33%;
  opacity: 0.603830728;
  -webkit-transform: rotate(266.3692927285deg);
  transform: rotate(266.3692927285deg);
  -webkit-animation: drop-78 4.7689998138s 0.3077934929s infinite;
  animation: drop-78 4.7689998138s 0.3077934929s infinite;
}

@-webkit-keyframes drop-78 {
  100% {
    top: 110%;
    left: 48%;
  }
}

@keyframes drop-78 {
  100% {
    top: 110%;
    left: 48%;
  }
}
.IAconfetti-79 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 42%;
  opacity: 0.7534387308;
  -webkit-transform: rotate(135.1950055839deg);
  transform: rotate(135.1950055839deg);
  -webkit-animation: drop-79 4.4403643678s 0.5053381686s infinite;
  animation: drop-79 4.4403643678s 0.5053381686s infinite;
}

@-webkit-keyframes drop-79 {
  100% {
    top: 110%;
    left: 46%;
  }
}

@keyframes drop-79 {
  100% {
    top: 110%;
    left: 46%;
  }
}
.IAconfetti-80 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 23%;
  opacity: 1.1802325089;
  -webkit-transform: rotate(105.3588935745deg);
  transform: rotate(105.3588935745deg);
  -webkit-animation: drop-80 4.5319745664s 0.2243987629s infinite;
  animation: drop-80 4.5319745664s 0.2243987629s infinite;
}

@-webkit-keyframes drop-80 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-80 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.IAconfetti-81 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 25%;
  opacity: 0.6840640886;
  -webkit-transform: rotate(119.347165445deg);
  transform: rotate(119.347165445deg);
  -webkit-animation: drop-81 4.9406520917s 0.5271761295s infinite;
  animation: drop-81 4.9406520917s 0.5271761295s infinite;
}

@-webkit-keyframes drop-81 {
  100% {
    top: 110%;
    left: 40%;
  }
}

@keyframes drop-81 {
  100% {
    top: 110%;
    left: 40%;
  }
}
.IAconfetti-82 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 99%;
  opacity: 0.5043166331;
  -webkit-transform: rotate(260.0343232988deg);
  transform: rotate(260.0343232988deg);
  -webkit-animation: drop-82 4.0476718573s 0.9405973354s infinite;
  animation: drop-82 4.0476718573s 0.9405973354s infinite;
}

@-webkit-keyframes drop-82 {
  100% {
    top: 110%;
    left: 109%;
  }
}

@keyframes drop-82 {
  100% {
    top: 110%;
    left: 109%;
  }
}
.IAconfetti-83 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 100%;
  opacity: 1.2411954792;
  -webkit-transform: rotate(114.2535721132deg);
  transform: rotate(114.2535721132deg);
  -webkit-animation: drop-83 4.3645591115s 0.5323816976s infinite;
  animation: drop-83 4.3645591115s 0.5323816976s infinite;
}

@-webkit-keyframes drop-83 {
  100% {
    top: 110%;
    left: 104%;
  }
}

@keyframes drop-83 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.IAconfetti-84 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 58%;
  opacity: 0.5850793774;
  -webkit-transform: rotate(58.8707575029deg);
  transform: rotate(58.8707575029deg);
  -webkit-animation: drop-84 4.1920759311s 0.5426350079s infinite;
  animation: drop-84 4.1920759311s 0.5426350079s infinite;
}

@-webkit-keyframes drop-84 {
  100% {
    top: 110%;
    left: 60%;
  }
}

@keyframes drop-84 {
  100% {
    top: 110%;
    left: 60%;
  }
}
.IAconfetti-85 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 13%;
  opacity: 0.9657930982;
  -webkit-transform: rotate(279.3128359692deg);
  transform: rotate(279.3128359692deg);
  -webkit-animation: drop-85 4.4729119678s 0.2297265158s infinite;
  animation: drop-85 4.4729119678s 0.2297265158s infinite;
}

@-webkit-keyframes drop-85 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-85 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.IAconfetti-86 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 26%;
  opacity: 0.7364559812;
  -webkit-transform: rotate(232.6250975052deg);
  transform: rotate(232.6250975052deg);
  -webkit-animation: drop-86 4.3948459408s 0.9304317602s infinite;
  animation: drop-86 4.3948459408s 0.9304317602s infinite;
}

@-webkit-keyframes drop-86 {
  100% {
    top: 110%;
    left: 33%;
  }
}

@keyframes drop-86 {
  100% {
    top: 110%;
    left: 33%;
  }
}
.IAconfetti-87 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 80%;
  opacity: 0.8330485304;
  -webkit-transform: rotate(57.6459716198deg);
  transform: rotate(57.6459716198deg);
  -webkit-animation: drop-87 4.0568876737s 0.9419291857s infinite;
  animation: drop-87 4.0568876737s 0.9419291857s infinite;
}

@-webkit-keyframes drop-87 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-87 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.IAconfetti-88 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 46%;
  opacity: 0.9293397556;
  -webkit-transform: rotate(271.5873523411deg);
  transform: rotate(271.5873523411deg);
  -webkit-animation: drop-88 4.343054764s 0.2818976098s infinite;
  animation: drop-88 4.343054764s 0.2818976098s infinite;
}

@-webkit-keyframes drop-88 {
  100% {
    top: 110%;
    left: 61%;
  }
}

@keyframes drop-88 {
  100% {
    top: 110%;
    left: 61%;
  }
}
.IAconfetti-89 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 42%;
  opacity: 0.8688745225;
  -webkit-transform: rotate(339.4877981793deg);
  transform: rotate(339.4877981793deg);
  -webkit-animation: drop-89 4.9452085872s 0.2616810818s infinite;
  animation: drop-89 4.9452085872s 0.2616810818s infinite;
}

@-webkit-keyframes drop-89 {
  100% {
    top: 110%;
    left: 46%;
  }
}

@keyframes drop-89 {
  100% {
    top: 110%;
    left: 46%;
  }
}
.IAconfetti-90 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 58%;
  opacity: 0.7522782693;
  -webkit-transform: rotate(305.9311179062deg);
  transform: rotate(305.9311179062deg);
  -webkit-animation: drop-90 4.2325230448s 0.6831669092s infinite;
  animation: drop-90 4.2325230448s 0.6831669092s infinite;
}

@-webkit-keyframes drop-90 {
  100% {
    top: 110%;
    left: 69%;
  }
}

@keyframes drop-90 {
  100% {
    top: 110%;
    left: 69%;
  }
}
.IAconfetti-91 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 90%;
  opacity: 0.8582272721;
  -webkit-transform: rotate(233.9047723155deg);
  transform: rotate(233.9047723155deg);
  -webkit-animation: drop-91 4.1628954187s 0.2176694594s infinite;
  animation: drop-91 4.1628954187s 0.2176694594s infinite;
}

@-webkit-keyframes drop-91 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-91 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.IAconfetti-92 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 63%;
  opacity: 1.0444316542;
  -webkit-transform: rotate(250.4055297918deg);
  transform: rotate(250.4055297918deg);
  -webkit-animation: drop-92 4.4352245664s 0.2963066426s infinite;
  animation: drop-92 4.4352245664s 0.2963066426s infinite;
}

@-webkit-keyframes drop-92 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-92 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.IAconfetti-93 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 79%;
  opacity: 0.8997080328;
  -webkit-transform: rotate(242.2754009318deg);
  transform: rotate(242.2754009318deg);
  -webkit-animation: drop-93 4.3504063386s 0.319096717s infinite;
  animation: drop-93 4.3504063386s 0.319096717s infinite;
}

@-webkit-keyframes drop-93 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-93 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.IAconfetti-94 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 24%;
  opacity: 1.3475311499;
  -webkit-transform: rotate(271.9827377651deg);
  transform: rotate(271.9827377651deg);
  -webkit-animation: drop-94 4.8766521198s 0.833852968s infinite;
  animation: drop-94 4.8766521198s 0.833852968s infinite;
}

@-webkit-keyframes drop-94 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-94 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.IAconfetti-95 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 17%;
  opacity: 0.9756714061;
  -webkit-transform: rotate(325.9477038205deg);
  transform: rotate(325.9477038205deg);
  -webkit-animation: drop-95 4.5936551632s 0.1144148605s infinite;
  animation: drop-95 4.5936551632s 0.1144148605s infinite;
}

@-webkit-keyframes drop-95 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-95 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.IAconfetti-96 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 80%;
  opacity: 0.9774934228;
  -webkit-transform: rotate(242.3335399deg);
  transform: rotate(242.3335399deg);
  -webkit-animation: drop-96 4.711132773s 0.4044439359s infinite;
  animation: drop-96 4.711132773s 0.4044439359s infinite;
}

@-webkit-keyframes drop-96 {
  100% {
    top: 110%;
    left: 93%;
  }
}

@keyframes drop-96 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.IAconfetti-97 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 60%;
  opacity: 1.3132713451;
  -webkit-transform: rotate(67.5495304124deg);
  transform: rotate(67.5495304124deg);
  -webkit-animation: drop-97 4.108771752s 0.0645714085s infinite;
  animation: drop-97 4.108771752s 0.0645714085s infinite;
}

@-webkit-keyframes drop-97 {
  100% {
    top: 110%;
    left: 68%;
  }
}

@keyframes drop-97 {
  100% {
    top: 110%;
    left: 68%;
  }
}
.IAconfetti-98 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 19%;
  opacity: 0.5309206722;
  -webkit-transform: rotate(149.603557906deg);
  transform: rotate(149.603557906deg);
  -webkit-animation: drop-98 4.5598788633s 0.0795507593s infinite;
  animation: drop-98 4.5598788633s 0.0795507593s infinite;
}

@-webkit-keyframes drop-98 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-98 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.IAconfetti-99 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 83%;
  opacity: 1.1439886234;
  -webkit-transform: rotate(111.62982513deg);
  transform: rotate(111.62982513deg);
  -webkit-animation: drop-99 4.5806503648s 0.4603226843s infinite;
  animation: drop-99 4.5806503648s 0.4603226843s infinite;
}

@-webkit-keyframes drop-99 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-99 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.IAconfetti-100 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 30%;
  opacity: 0.6997133412;
  -webkit-transform: rotate(96.231467776deg);
  transform: rotate(96.231467776deg);
  -webkit-animation: drop-100 4.5671212834s 0.0418807076s infinite;
  animation: drop-100 4.5671212834s 0.0418807076s infinite;
}

@-webkit-keyframes drop-100 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-100 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.IAconfetti-101 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 95%;
  opacity: 1.3147730516;
  -webkit-transform: rotate(31.4450715956deg);
  transform: rotate(31.4450715956deg);
  -webkit-animation: drop-101 4.4799404398s 0.3187441151s infinite;
  animation: drop-101 4.4799404398s 0.3187441151s infinite;
}

@-webkit-keyframes drop-101 {
  100% {
    top: 110%;
    left: 108%;
  }
}

@keyframes drop-101 {
  100% {
    top: 110%;
    left: 108%;
  }
}
.IAconfetti-102 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 45%;
  opacity: 1.2149035917;
  -webkit-transform: rotate(88.7300702674deg);
  transform: rotate(88.7300702674deg);
  -webkit-animation: drop-102 4.7487476159s 0.1550580712s infinite;
  animation: drop-102 4.7487476159s 0.1550580712s infinite;
}

@-webkit-keyframes drop-102 {
  100% {
    top: 110%;
    left: 52%;
  }
}

@keyframes drop-102 {
  100% {
    top: 110%;
    left: 52%;
  }
}
.IAconfetti-103 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 14%;
  opacity: 0.9465377949;
  -webkit-transform: rotate(279.9254348656deg);
  transform: rotate(279.9254348656deg);
  -webkit-animation: drop-103 4.0465040403s 0.1455166403s infinite;
  animation: drop-103 4.0465040403s 0.1455166403s infinite;
}

@-webkit-keyframes drop-103 {
  100% {
    top: 110%;
    left: 24%;
  }
}

@keyframes drop-103 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.IAconfetti-104 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 56%;
  opacity: 0.757447171;
  -webkit-transform: rotate(188.0350380214deg);
  transform: rotate(188.0350380214deg);
  -webkit-animation: drop-104 4.398154588s 0.5064124923s infinite;
  animation: drop-104 4.398154588s 0.5064124923s infinite;
}

@-webkit-keyframes drop-104 {
  100% {
    top: 110%;
    left: 59%;
  }
}

@keyframes drop-104 {
  100% {
    top: 110%;
    left: 59%;
  }
}
.IAconfetti-105 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 50%;
  opacity: 1.4791823762;
  -webkit-transform: rotate(213.9040561336deg);
  transform: rotate(213.9040561336deg);
  -webkit-animation: drop-105 4.6004864112s 0.1291061981s infinite;
  animation: drop-105 4.6004864112s 0.1291061981s infinite;
}

@-webkit-keyframes drop-105 {
  100% {
    top: 110%;
    left: 57%;
  }
}

@keyframes drop-105 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.IAconfetti-106 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 69%;
  opacity: 1.3622136621;
  -webkit-transform: rotate(205.7308696978deg);
  transform: rotate(205.7308696978deg);
  -webkit-animation: drop-106 4.7421470646s 0.7965847784s infinite;
  animation: drop-106 4.7421470646s 0.7965847784s infinite;
}

@-webkit-keyframes drop-106 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-106 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.IAconfetti-107 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 58%;
  opacity: 0.9803681781;
  -webkit-transform: rotate(28.9093255885deg);
  transform: rotate(28.9093255885deg);
  -webkit-animation: drop-107 4.2357066638s 0.898641963s infinite;
  animation: drop-107 4.2357066638s 0.898641963s infinite;
}

@-webkit-keyframes drop-107 {
  100% {
    top: 110%;
    left: 62%;
  }
}

@keyframes drop-107 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.IAconfetti-108 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 98%;
  opacity: 1.3971245117;
  -webkit-transform: rotate(240.036520939deg);
  transform: rotate(240.036520939deg);
  -webkit-animation: drop-108 4.176549448s 0.9656586744s infinite;
  animation: drop-108 4.176549448s 0.9656586744s infinite;
}

@-webkit-keyframes drop-108 {
  100% {
    top: 110%;
    left: 106%;
  }
}

@keyframes drop-108 {
  100% {
    top: 110%;
    left: 106%;
  }
}
.IAconfetti-109 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 3%;
  opacity: 0.7671518771;
  -webkit-transform: rotate(100.0202468709deg);
  transform: rotate(100.0202468709deg);
  -webkit-animation: drop-109 4.7168781034s 0.3097587328s infinite;
  animation: drop-109 4.7168781034s 0.3097587328s infinite;
}

@-webkit-keyframes drop-109 {
  100% {
    top: 110%;
    left: 6%;
  }
}

@keyframes drop-109 {
  100% {
    top: 110%;
    left: 6%;
  }
}
.IAconfetti-110 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 85%;
  opacity: 1.2779794785;
  -webkit-transform: rotate(348.37813345deg);
  transform: rotate(348.37813345deg);
  -webkit-animation: drop-110 4.5848902595s 0.6017671469s infinite;
  animation: drop-110 4.5848902595s 0.6017671469s infinite;
}

@-webkit-keyframes drop-110 {
  100% {
    top: 110%;
    left: 95%;
  }
}

@keyframes drop-110 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.IAconfetti-111 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 54%;
  opacity: 1.1774324316;
  -webkit-transform: rotate(215.449922455deg);
  transform: rotate(215.449922455deg);
  -webkit-animation: drop-111 4.2716017679s 0.5145518138s infinite;
  animation: drop-111 4.2716017679s 0.5145518138s infinite;
}

@-webkit-keyframes drop-111 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-111 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.IAconfetti-112 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 36%;
  opacity: 1.0899785795;
  -webkit-transform: rotate(151.3045740649deg);
  transform: rotate(151.3045740649deg);
  -webkit-animation: drop-112 4.8907778448s 0.1412178875s infinite;
  animation: drop-112 4.8907778448s 0.1412178875s infinite;
}

@-webkit-keyframes drop-112 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-112 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.IAconfetti-113 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 78%;
  opacity: 1.0017506791;
  -webkit-transform: rotate(56.9009213384deg);
  transform: rotate(56.9009213384deg);
  -webkit-animation: drop-113 4.736779895s 0.9951468887s infinite;
  animation: drop-113 4.736779895s 0.9951468887s infinite;
}

@-webkit-keyframes drop-113 {
  100% {
    top: 110%;
    left: 79%;
  }
}

@keyframes drop-113 {
  100% {
    top: 110%;
    left: 79%;
  }
}
.IAconfetti-114 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 16%;
  opacity: 1.4947396951;
  -webkit-transform: rotate(124.5200829221deg);
  transform: rotate(124.5200829221deg);
  -webkit-animation: drop-114 4.2200949323s 0.194239386s infinite;
  animation: drop-114 4.2200949323s 0.194239386s infinite;
}

@-webkit-keyframes drop-114 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-114 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.IAconfetti-115 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 32%;
  opacity: 1.214345004;
  -webkit-transform: rotate(6.2284163946deg);
  transform: rotate(6.2284163946deg);
  -webkit-animation: drop-115 4.1258350316s 0.1157922548s infinite;
  animation: drop-115 4.1258350316s 0.1157922548s infinite;
}

@-webkit-keyframes drop-115 {
  100% {
    top: 110%;
    left: 38%;
  }
}

@keyframes drop-115 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.IAconfetti-116 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 42%;
  opacity: 1.0977064617;
  -webkit-transform: rotate(142.6125828878deg);
  transform: rotate(142.6125828878deg);
  -webkit-animation: drop-116 4.3989268685s 0.6637658655s infinite;
  animation: drop-116 4.3989268685s 0.6637658655s infinite;
}

@-webkit-keyframes drop-116 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-116 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.IAconfetti-117 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 89%;
  opacity: 1.1684275772;
  -webkit-transform: rotate(301.9770650639deg);
  transform: rotate(301.9770650639deg);
  -webkit-animation: drop-117 4.7434522158s 0.8214770981s infinite;
  animation: drop-117 4.7434522158s 0.8214770981s infinite;
}

@-webkit-keyframes drop-117 {
  100% {
    top: 110%;
    left: 97%;
  }
}

@keyframes drop-117 {
  100% {
    top: 110%;
    left: 97%;
  }
}
.IAconfetti-118 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 40%;
  opacity: 1.0805483753;
  -webkit-transform: rotate(306.5273602591deg);
  transform: rotate(306.5273602591deg);
  -webkit-animation: drop-118 4.6240734828s 0.5112465331s infinite;
  animation: drop-118 4.6240734828s 0.5112465331s infinite;
}

@-webkit-keyframes drop-118 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-118 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.IAconfetti-119 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 79%;
  opacity: 0.8054825006;
  -webkit-transform: rotate(205.3314353741deg);
  transform: rotate(205.3314353741deg);
  -webkit-animation: drop-119 4.1488027865s 0.2851109575s infinite;
  animation: drop-119 4.1488027865s 0.2851109575s infinite;
}

@-webkit-keyframes drop-119 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-119 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.IAconfetti-120 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 21%;
  opacity: 0.8160701921;
  -webkit-transform: rotate(71.1451739827deg);
  transform: rotate(71.1451739827deg);
  -webkit-animation: drop-120 4.0297074976s 0.6092273211s infinite;
  animation: drop-120 4.0297074976s 0.6092273211s infinite;
}

@-webkit-keyframes drop-120 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-120 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.IAconfetti-121 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 16%;
  opacity: 0.9740698352;
  -webkit-transform: rotate(243.0051402813deg);
  transform: rotate(243.0051402813deg);
  -webkit-animation: drop-121 4.3691592464s 0.8321383393s infinite;
  animation: drop-121 4.3691592464s 0.8321383393s infinite;
}

@-webkit-keyframes drop-121 {
  100% {
    top: 110%;
    left: 24%;
  }
}

@keyframes drop-121 {
  100% {
    top: 110%;
    left: 24%;
  }
}
.IAconfetti-122 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 18%;
  opacity: 0.6376935208;
  -webkit-transform: rotate(261.2002597512deg);
  transform: rotate(261.2002597512deg);
  -webkit-animation: drop-122 4.1906819798s 0.2083842313s infinite;
  animation: drop-122 4.1906819798s 0.2083842313s infinite;
}

@-webkit-keyframes drop-122 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-122 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.IAconfetti-123 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 23%;
  opacity: 1.0287519456;
  -webkit-transform: rotate(121.5862369594deg);
  transform: rotate(121.5862369594deg);
  -webkit-animation: drop-123 4.5606104733s 0.6871979875s infinite;
  animation: drop-123 4.5606104733s 0.6871979875s infinite;
}

@-webkit-keyframes drop-123 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-123 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.IAconfetti-124 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 100%;
  opacity: 0.7761869594;
  -webkit-transform: rotate(28.507169006deg);
  transform: rotate(28.507169006deg);
  -webkit-animation: drop-124 4.7895091609s 0.1930449638s infinite;
  animation: drop-124 4.7895091609s 0.1930449638s infinite;
}

@-webkit-keyframes drop-124 {
  100% {
    top: 110%;
    left: 112%;
  }
}

@keyframes drop-124 {
  100% {
    top: 110%;
    left: 112%;
  }
}
.IAconfetti-125 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 64%;
  opacity: 1.1685555392;
  -webkit-transform: rotate(179.8758509378deg);
  transform: rotate(179.8758509378deg);
  -webkit-animation: drop-125 4.6722152048s 0.0012183188s infinite;
  animation: drop-125 4.6722152048s 0.0012183188s infinite;
}

@-webkit-keyframes drop-125 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-125 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.IAconfetti-126 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 3%;
  opacity: 1.1535386131;
  -webkit-transform: rotate(3.9384785817deg);
  transform: rotate(3.9384785817deg);
  -webkit-animation: drop-126 4.5244374945s 0.0673930125s infinite;
  animation: drop-126 4.5244374945s 0.0673930125s infinite;
}

@-webkit-keyframes drop-126 {
  100% {
    top: 110%;
    left: 15%;
  }
}

@keyframes drop-126 {
  100% {
    top: 110%;
    left: 15%;
  }
}
.IAconfetti-127 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 31%;
  opacity: 1.1997978332;
  -webkit-transform: rotate(263.6860433294deg);
  transform: rotate(263.6860433294deg);
  -webkit-animation: drop-127 4.7950886907s 0.7790227771s infinite;
  animation: drop-127 4.7950886907s 0.7790227771s infinite;
}

@-webkit-keyframes drop-127 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-127 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.IAconfetti-128 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 70%;
  opacity: 1.4693934469;
  -webkit-transform: rotate(267.1512863668deg);
  transform: rotate(267.1512863668deg);
  -webkit-animation: drop-128 4.5866393749s 0.6344844157s infinite;
  animation: drop-128 4.5866393749s 0.6344844157s infinite;
}

@-webkit-keyframes drop-128 {
  100% {
    top: 110%;
    left: 76%;
  }
}

@keyframes drop-128 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.IAconfetti-129 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 2%;
  opacity: 0.6099198434;
  -webkit-transform: rotate(154.7232578737deg);
  transform: rotate(154.7232578737deg);
  -webkit-animation: drop-129 4.2128255783s 0.054935175s infinite;
  animation: drop-129 4.2128255783s 0.054935175s infinite;
}

@-webkit-keyframes drop-129 {
  100% {
    top: 110%;
    left: 17%;
  }
}

@keyframes drop-129 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.IAconfetti-130 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 6%;
  opacity: 0.9244781429;
  -webkit-transform: rotate(303.8316214853deg);
  transform: rotate(303.8316214853deg);
  -webkit-animation: drop-130 4.3417637532s 0.3171428086s infinite;
  animation: drop-130 4.3417637532s 0.3171428086s infinite;
}

@-webkit-keyframes drop-130 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-130 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.IAconfetti-131 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 54%;
  opacity: 0.9420804107;
  -webkit-transform: rotate(309.0613846182deg);
  transform: rotate(309.0613846182deg);
  -webkit-animation: drop-131 4.5723916345s 0.597942721s infinite;
  animation: drop-131 4.5723916345s 0.597942721s infinite;
}

@-webkit-keyframes drop-131 {
  100% {
    top: 110%;
    left: 55%;
  }
}

@keyframes drop-131 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.IAconfetti-132 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 37%;
  opacity: 1.375776466;
  -webkit-transform: rotate(221.8152082454deg);
  transform: rotate(221.8152082454deg);
  -webkit-animation: drop-132 4.1125105886s 0.0862690858s infinite;
  animation: drop-132 4.1125105886s 0.0862690858s infinite;
}

@-webkit-keyframes drop-132 {
  100% {
    top: 110%;
    left: 52%;
  }
}

@keyframes drop-132 {
  100% {
    top: 110%;
    left: 52%;
  }
}
.IAconfetti-133 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 21%;
  opacity: 0.9586322566;
  -webkit-transform: rotate(169.2644752136deg);
  transform: rotate(169.2644752136deg);
  -webkit-animation: drop-133 4.3820836177s 0.2713924415s infinite;
  animation: drop-133 4.3820836177s 0.2713924415s infinite;
}

@-webkit-keyframes drop-133 {
  100% {
    top: 110%;
    left: 26%;
  }
}

@keyframes drop-133 {
  100% {
    top: 110%;
    left: 26%;
  }
}
.IAconfetti-134 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 10%;
  opacity: 0.7212641074;
  -webkit-transform: rotate(74.0875626466deg);
  transform: rotate(74.0875626466deg);
  -webkit-animation: drop-134 4.7104321165s 0.211504237s infinite;
  animation: drop-134 4.7104321165s 0.211504237s infinite;
}

@-webkit-keyframes drop-134 {
  100% {
    top: 110%;
    left: 17%;
  }
}

@keyframes drop-134 {
  100% {
    top: 110%;
    left: 17%;
  }
}
.IAconfetti-135 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 59%;
  opacity: 0.5620595122;
  -webkit-transform: rotate(304.3065806162deg);
  transform: rotate(304.3065806162deg);
  -webkit-animation: drop-135 4.9234591084s 0.9199417331s infinite;
  animation: drop-135 4.9234591084s 0.9199417331s infinite;
}

@-webkit-keyframes drop-135 {
  100% {
    top: 110%;
    left: 74%;
  }
}

@keyframes drop-135 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.IAconfetti-136 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 25%;
  opacity: 1.484107086;
  -webkit-transform: rotate(213.1776916989deg);
  transform: rotate(213.1776916989deg);
  -webkit-animation: drop-136 4.1734496659s 0.4541711952s infinite;
  animation: drop-136 4.1734496659s 0.4541711952s infinite;
}

@-webkit-keyframes drop-136 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-136 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.IAconfetti-137 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 37%;
  opacity: 1.1240174023;
  -webkit-transform: rotate(233.1812722638deg);
  transform: rotate(233.1812722638deg);
  -webkit-animation: drop-137 4.1281979239s 0.9005495967s infinite;
  animation: drop-137 4.1281979239s 0.9005495967s infinite;
}

@-webkit-keyframes drop-137 {
  100% {
    top: 110%;
    left: 52%;
  }
}

@keyframes drop-137 {
  100% {
    top: 110%;
    left: 52%;
  }
}
.IAconfetti-138 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 30%;
  opacity: 1.0244795847;
  -webkit-transform: rotate(200.5474954423deg);
  transform: rotate(200.5474954423deg);
  -webkit-animation: drop-138 4.4032391255s 0.7373261598s infinite;
  animation: drop-138 4.4032391255s 0.7373261598s infinite;
}

@-webkit-keyframes drop-138 {
  100% {
    top: 110%;
    left: 44%;
  }
}

@keyframes drop-138 {
  100% {
    top: 110%;
    left: 44%;
  }
}
.IAconfetti-139 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 26%;
  opacity: 0.7178898583;
  -webkit-transform: rotate(126.2409144121deg);
  transform: rotate(126.2409144121deg);
  -webkit-animation: drop-139 4.3569919779s 0.9676201047s infinite;
  animation: drop-139 4.3569919779s 0.9676201047s infinite;
}

@-webkit-keyframes drop-139 {
  100% {
    top: 110%;
    left: 37%;
  }
}

@keyframes drop-139 {
  100% {
    top: 110%;
    left: 37%;
  }
}
.IAconfetti-140 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 95%;
  opacity: 0.50112999;
  -webkit-transform: rotate(267.166274838deg);
  transform: rotate(267.166274838deg);
  -webkit-animation: drop-140 4.7406398908s 0.9991085523s infinite;
  animation: drop-140 4.7406398908s 0.9991085523s infinite;
}

@-webkit-keyframes drop-140 {
  100% {
    top: 110%;
    left: 109%;
  }
}

@keyframes drop-140 {
  100% {
    top: 110%;
    left: 109%;
  }
}
.IAconfetti-141 {
  width: 4px;
  height: 1.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 83%;
  opacity: 1.071846613;
  -webkit-transform: rotate(120.6921731332deg);
  transform: rotate(120.6921731332deg);
  -webkit-animation: drop-141 4.347141588s 0.863317036s infinite;
  animation: drop-141 4.347141588s 0.863317036s infinite;
}

@-webkit-keyframes drop-141 {
  100% {
    top: 110%;
    left: 93%;
  }
}

@keyframes drop-141 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.IAconfetti-142 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 65%;
  opacity: 1.4010595743;
  -webkit-transform: rotate(173.0781228941deg);
  transform: rotate(173.0781228941deg);
  -webkit-animation: drop-142 4.5840712076s 0.2077924892s infinite;
  animation: drop-142 4.5840712076s 0.2077924892s infinite;
}

@-webkit-keyframes drop-142 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-142 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.IAconfetti-143 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 94%;
  opacity: 1.1182972836;
  -webkit-transform: rotate(173.9838503086deg);
  transform: rotate(173.9838503086deg);
  -webkit-animation: drop-143 4.0671420618s 0.0137643563s infinite;
  animation: drop-143 4.0671420618s 0.0137643563s infinite;
}

@-webkit-keyframes drop-143 {
  100% {
    top: 110%;
    left: 107%;
  }
}

@keyframes drop-143 {
  100% {
    top: 110%;
    left: 107%;
  }
}
.IAconfetti-144 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 47%;
  opacity: 1.2847654054;
  -webkit-transform: rotate(27.2409894642deg);
  transform: rotate(27.2409894642deg);
  -webkit-animation: drop-144 4.0956256634s 0.9177208523s infinite;
  animation: drop-144 4.0956256634s 0.9177208523s infinite;
}

@-webkit-keyframes drop-144 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-144 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.IAconfetti-145 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 95%;
  opacity: 1.0351097559;
  -webkit-transform: rotate(46.7455229647deg);
  transform: rotate(46.7455229647deg);
  -webkit-animation: drop-145 4.0099590945s 0.2790475475s infinite;
  animation: drop-145 4.0099590945s 0.2790475475s infinite;
}

@-webkit-keyframes drop-145 {
  100% {
    top: 110%;
    left: 107%;
  }
}

@keyframes drop-145 {
  100% {
    top: 110%;
    left: 107%;
  }
}
.IAconfetti-146 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 47%;
  opacity: 1.225985944;
  -webkit-transform: rotate(45.7551582764deg);
  transform: rotate(45.7551582764deg);
  -webkit-animation: drop-146 4.4409323079s 0.995145691s infinite;
  animation: drop-146 4.4409323079s 0.995145691s infinite;
}

@-webkit-keyframes drop-146 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-146 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.IAconfetti-147 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 96%;
  opacity: 1.2896500029;
  -webkit-transform: rotate(209.0359612543deg);
  transform: rotate(209.0359612543deg);
  -webkit-animation: drop-147 4.5630776891s 0.7556517032s infinite;
  animation: drop-147 4.5630776891s 0.7556517032s infinite;
}

@-webkit-keyframes drop-147 {
  100% {
    top: 110%;
    left: 104%;
  }
}

@keyframes drop-147 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.IAconfetti-148 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 67%;
  opacity: 1.3698384865;
  -webkit-transform: rotate(286.8034838083deg);
  transform: rotate(286.8034838083deg);
  -webkit-animation: drop-148 4.3641864459s 0.6220671038s infinite;
  animation: drop-148 4.3641864459s 0.6220671038s infinite;
}

@-webkit-keyframes drop-148 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-148 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.IAconfetti-149 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 42%;
  opacity: 1.2112988853;
  -webkit-transform: rotate(157.0655446298deg);
  transform: rotate(157.0655446298deg);
  -webkit-animation: drop-149 4.9012737999s 0.7024778798s infinite;
  animation: drop-149 4.9012737999s 0.7024778798s infinite;
}

@-webkit-keyframes drop-149 {
  100% {
    top: 110%;
    left: 56%;
  }
}

@keyframes drop-149 {
  100% {
    top: 110%;
    left: 56%;
  }
}
.IAconfetti-150 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 85%;
  opacity: 0.7020115951;
  -webkit-transform: rotate(149.8303082152deg);
  transform: rotate(149.8303082152deg);
  -webkit-animation: drop-150 4.2369444891s 0.9034611115s infinite;
  animation: drop-150 4.2369444891s 0.9034611115s infinite;
}

@-webkit-keyframes drop-150 {
  100% {
    top: 110%;
    left: 89%;
  }
}

@keyframes drop-150 {
  100% {
    top: 110%;
    left: 89%;
  }
}
