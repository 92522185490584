@value colorDarkBlue, colorMediumBlue from '../../../../../../variables/colors.css';
@value borderRadius6, borderSize, size5, size11, size14, size18 from "../../../../../../variables/sizes.css";
@value space3, space5, space9 from "../../../../../../variables/spaces.css";

.sendButton {
  padding: 0 space9;
  background-color: colorMediumBlue;
  border-radius: borderRadius6;
}

.sendButton.inactive {
  opacity: 0.25;
}

.label {
  color: #ffffff;
  font-weight: bold;
  font-size: size14;
}
