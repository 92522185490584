@value colorWhite, colorLightGrey from './../../variables/colors.css';
@value windowWidth from './../../variables/sizes.css';

.helpPage {
  background-color: colorLightGrey;
  margin: 18px auto;
}

.videoPlayer {
  width: 100%;
  height: auto;
}
