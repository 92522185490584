@value colorDarkBlue, colorMediumBlue, colorWhite, colorLightGrey from './../../../variables/colors.css';
@value borderRadius, size16, size40, size18, size100 from './../../../variables/sizes.css';
@value space1, space2, space3, space4, space5, space10, space12, space15, space18, space20, space30, space90, space200, space300 from './../../../variables/spaces.css';

.divContainer {
  width: 100%;
}

.statHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: space12;
}

.stat {
  align-self: center;
  margin-top: space12;
  font-size: size40;
}

.statLabel {
  font-weight: normal;
  font-size: size16;
}

.cardContainer {
  padding: space15 space10;
  background-color: colorMediumBlue;
  color: colorWhite;
  border-radius: borderRadius;
  box-shadow: 0 space2 space4 0 rgba(0, 0, 0, 0.2),
    0 space3 space10 0 rgba(0, 0, 0, 0.19);
  height: space200;
}

.cardTitle {
  margin-left: auto;
  margin-right: auto;
}

.cardText {
  font-size: size18;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: space15;
}

.divider-grid {
  display: grid;
  column-gap: 0.9rem;
  grid-template-columns: minmax(0, 1fr) max-content minmax(0, 1fr);
}

.divider-grid h1 {
  margin-left: 0;
  margin-right: 0;
}

.divider {
  width: space1;
  height: space90;
  border: space3 solid white;
  background-color: white;
  border-radius: space10;
  box-shadow: 0 space2 space4 0 rgba(0, 0, 0, 0.2),
    0 space3 space10 0 rgba(0, 0, 0, 0.19);
}

.tippy {
  width: space300;
  color: colorDarkBlue;
  font-size: size16;
  font-weight: 500;
  text-align: center;
  background-color: colorWhite;
  border-radius: borderRadius;
  padding: space10;
  margin-right: 1rem;
}

.tippy p:last-child {
  margin-bottom: 0;
}

.scrollableTippy {
  background-color: colorWhite;
  max-height: 200px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: borderRadius;
}

.scrollableTippy::-webkit-scrollbar-track {
  border: solid space1 colorLightGrey;
  border-top-right-radius: borderRadius;
  border-bottom-right-radius: borderRadius;
}

.scrollableTippy::-webkit-scrollbar-thumb {
  background: colorDarkBlue;
}

.button {
  font-size: size16;
  align-self: center;
  width: space90;
  height: space30;
  padding: space5;
  color: colorDarkBlue;
  background-color: colorWhite;
  border-radius: borderRadius;
  box-shadow: 0 space2 space4 0 rgba(0, 0, 0, 0.2),
    0 space3 space10 0 rgba(0, 0, 0, 0.19);
}

.hideButton {
  color: colorMediumBlue;
}

.textShadow {
  text-shadow: space3 space3 space3 rgba(0, 0, 0, 0.2);
}

.iconContainer {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  line-height: space20;
}

.infoIcon {
  width: fit-content;
}

.infoIcon img {
  vertical-align: unset;
}

.spinner {
  align-self: center;
  margin-bottom: space18;
  margin-top: space10;
}
