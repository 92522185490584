@value colorDarkBlue, colorLightBlue from './../../variables/colors.css';
@value space3 from './../../variables/spaces.css';
@value size14 from "./../../variables/sizes.css";

.managerHolder {
  background-color: colorLightBlue;
  display: inline-block;
  padding: 0 space3;
  border-radius: space3;
  border: solid 0.75px colorDarkBlue;
  font-size: size14;
  font-weight: bold;
  color: colorDarkBlue;
}
