@value colorMediumBlue, colorDarkBlue, colorLightGrey, colorGrey, colorLightBlue, colorWhite from "./../../variables/colors.css";
@value size10, size16, size18, size20,size24, size30 from "./../../variables/sizes.css";
@value space3, space5, space9, space18, space30, space36, space72 from "./../../variables/spaces.css";

@value dotSize: 12px;
@value imageSize: 120px;

.picture {
  display: block;
  margin: auto;
  max-height: imageSize;
  min-height: imageSize;
  min-width: imageSize;
  max-width: imageSize;
  border-radius: 50%;
  box-shadow: 0 space5 space18 colorGrey;
  object-fit: cover !important;
}

.defaultPicture {
  max-height: imageSize;
  min-height: imageSize;
  min-width: imageSize;
  max-width: imageSize;
  border-radius: 50%;
  padding: space9;
}

.textLimit {
  overflow: hidden;
  margin: 12px 24px 12px 24px;
  height: 240px;
}

.bioText {
  color: colorDarkBlue;
  font-size: size16;
}
.cardBody {
  background-color: colorMediumBlue;
  border-radius: 0px 0px size16 size16;
  text-align: center;
  color: colorWhite;
  padding-bottom: space9;
}
.card {
  width: 500px;
  height: 500px;
  background-color: colorWhite;

  border-radius: size16;
  box-shadow: 0 space3 space9 0 colorGrey;
  transition: 0.3s;
}
.testCard {
  background-color: red !important;
}

.card:hover {
  box-shadow: 0 space9 space18 0 colorGrey;
}

.imgholder {
  padding: space18 space9 0px space9;
  text-align: center;
}

.buttonContainer {
  height: size18;
  text-align: center;
}

.name {
  font-family: 'roboto';
  font-size: size30;
}

.jobDetail {
  color: colorLightGrey;
  font-size: size20;
}

.email {
  color: colorLightGrey;
  font-size: size18;
}

.navigation {
  max-height: dotSize;
  min-height: dotSize;
  min-width: dotSize;
  max-width: dotSize;
  margin-right: space3;
  margin-bottom: space9;
  margin-left: space3;
}
