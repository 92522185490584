@value colorLightBlue, colorMediumBlue, colorDarkBlue, colorWhite, colorLightGrey from './../../../variables/colors.css';
@value borderSize, borderRadius, borderRadius6, size16, size18 from './../../../variables/sizes.css';
@value space3, space5, space10, space15, space20, space25, space30, space40 from './../../../variables/spaces.css';

.detailsContainer {
  display: flex;
  flex-direction: column;
  border-radius: borderRadius;
  overflow: hidden;
  background-color: colorWhite;
  height: 72vh;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.innerDetailsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  background-color: colorDarkBlue;
}

.mandatoryContainer {
  margin-right: space40;
}

.sessionTitle {
  display: flex;
  flex-direction: row;
  padding: space15;
  font-size: size18;
  font-weight: 500;
  color: colorWhite;
  background-color: colorDarkBlue;
}

.colHeaderRow {
  color: colorDarkBlue;
  font-weight: 500;
  padding: space3 space15 0 space15;
}

.colHeaderTitle {
  display: flex;
  align-items: center;
}

.icon {
  padding-right: space5;
  padding-top: space3;
  padding-bottom: space5;
}

.calendarIcon {
  padding-left: space40;
}

.colVerticalBorder {
  border-left: borderSize solid colorLightGrey;
  border-right: borderSize solid colorLightGrey;
}

.staffListColumns {
  color: colorDarkBlue;
  height: 100%;
  overflow: hidden;
  border-bottom: borderSize solid colorLightGrey;
  border-top: borderSize solid colorLightGrey;
  padding: 0 space15 0 space15;
}

.staffListContainer {
  height: 100%;
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: space15;
}

/* scrollBar */
.staffListContainer::-webkit-scrollbar {
  width: size16;
}

.staffListContainer::-webkit-scrollbar-track {
  border-left: borderSize solid colorLightGrey;
}

.staffListContainer::-webkit-scrollbar-thumb {
  background: colorDarkBlue;
  border-radius: borderRadius6;
}

.staffCol {
  padding-left: space15;
}

.listHeading {
  color: colorDeepBlue;
  font-style: italic;
  font-weight: 500;
  background-color: colorLightBlue;
  border-radius: borderRadius6;
  margin-top: space15;
  padding-left: space25;
  margin-right: space40;
}

.staffRow {
  margin-top: space15;
  margin-left: space25;
  opacity: 0.6;
}

.listHeadingCheckbox {
  color: colorDeepBlue;
  font-style: italic;
  font-weight: 500;
  background-color: colorLightBlue;
  border-radius: borderRadius6;
  margin-top: space15;
  margin-right: space40;
}

.staffRowCheckbox {
  margin-top: space15;
}

.sectionBreak {
  height: space20;
}

.selectAll {
  font-size: size18;
  font-weight: bold;
  color: colorMediumBlue;
}

.remindButton {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.remindButtonContainer {
  padding: space10 space10 space10 space30;
}

.darkBlueColor {
  color: colorDarkBlue;
}

.loadingSpinner {
  text-align: center;
  margin-top: space10;
}

.sentSpinner {
  margin-left: space10;
}
