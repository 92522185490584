@value colorYellow, colorMediumBlue, colorDarkBlue, colorLightBlue3, colorLightGrey, colorWhite from "./../../variables/colors.css";
@value size5, size10, size14, size40, fixed_width from "./../../variables/sizes.css";
@value space3, space5, space9, space18, space24 from "./../../variables/spaces.css";

@value pillar_width: 750px;
/* Larger than the pillar sizes */
@value bridge_top_width: 1200px;
@value bridge_edge_height: 50.9px;

/* For large screens */
@value pillar_width_large: 1250px;
@value bridge_top_width_large: 2000px;
@value bridge_edge_height_large: 84.07px;

@value pillar_badge_large_top: 105px;
@value pillar_badge_large_left: 85px;
@value pillar_badge_large_max_height: 85px;

#wrapper {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/homepageBackground.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#homeArea {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#homeArea > #bridgeTop {
  margin-top: auto;
  flex: 0 0 auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#homeArea > #bridgeTop img {
  width: bridge_top_width;
  opacity: 0.55;
}

#homeArea .bridgeTopLeft,
#homeArea .bridgeTopRight {
  margin-top: auto;
  background-repeat: repeat-x;
  background-size: contain;
  height: bridge_edge_height;
  opacity: 0.55;
}
#homeArea .bridgeTopLeft {
  background-image: url('./../../assets/leftPieceBridge.png');
}
#homeArea .bridgeTopRight {
  background-image: url('./../../assets/rightPieceBridge.png');
}
#homeArea > #bridgeMiddle {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  max-width: pillar_width;
}

#homeArea > #bottomWrapper > #bridgeBottom {
  background-color: colorMediumBlue;
  flex: 0;
  display: flex;
  flex-direction: row;
  color: colorWhite;
  margin-left: auto;
  margin-right: auto;
  width: pillar_width;
}

#homeArea > #bottomWrapper {
  flex: 0 0 auto;
  width: 100%;
  background-color: colorMediumBlue;
}

#bridgeMiddle > div {
  flex: 20%;
  text-align: center;
}

.bottomBlockEdge {
  flex: 20%;
  margin: 1% 2%;
  border: 7px solid;
  border-image: url('../../assets/GreyFrame.png') 28;
  display: table;
  position: relative;
}
.showBridgeTooltip {
  position: relative;
}

.showBridgeTooltip:hover > .tooltipBox,
.bottomBlockEdge:hover > .tooltipBox {
  position: absolute;
  bottom: 145px;
  left: 50%;
  transform: translate(-50%);
  display: block;
  z-index: 999;
}

.bottomBlockEdge:hover > .tooltipBox{
  bottom: auto;
  top: -120px;
}

.tooltipBox {
  display: none;
  border: 10px solid;
}

.tooltipBoxB {
  border-image: url('../../assets/ConfirmationBoxW.png') 80 80 fill;
}

.tooltipBoxW {
  border-image: url('../../assets/pop-up-box-w.png') 80 80 fill;
}

.tooltipBoxW .tooltipContent {
  background-color: colorLightGrey;
}

.tooltipBoxB .tooltipContent {
  background-color: colorLightBlue3;
}

.tooltipContent {
  padding: 0 space3;
  width: 200px;
  font-size: size14;
  color: colorDarkBlue;
  font-weight: bold;
}

.bottomBlockEdge > .tooltipBox > .tooltipContent{
  width: 250px;
  text-align: left;
}

.pillarBadge {
  position: absolute;
  max-height: 50px;
  top: 60px;
  left: 50px;
}

#bridgeBottom .bottomBlock {
  /* Block Design */
  word-spacing: 100vw; /* Force each word onto a new line */
  display: table-cell;
  border-radius: 5px;
  border: 0px solid transparent;
  width: 100%;
  /* background-color: colorMediumBlue;  */ /* In case Gradient not working */
  background-image: linear-gradient(colorMediumBlue, #002f5c);

  /* Text Display */
  text-align: center;
  font-size: size14;
  color: colorWhite;
  text-transform: uppercase;

  /* For Tooltip */
  position: relative;
}

#bridgeBottom .bottomBlock > span {
  padding: size5 size10;
}
#bridgeBottom .bottomBlock:hover,
#bridgeBottom .bottomBlock.selected {
  background-color: colorYellow;
  color: colorDarkBlue;
  background-image: none;
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
}

#bridgeMiddle .bridgePillarImg {
  width: 85%;
  margin-bottom: 0;
}

#bridgeMiddle .bridgePillarImg.fading {
  opacity: 0.55;
}

.tooltip2Spot {
  margin: 0;
}

/* Tooltip #2 */
#testTooltip2 {
  position: relative;
  display: inline-block;
}
.tooltip2 {
  width: 270px;
  background-color: colorLightGrey;
  color: #000000;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -5px;
}
.tooltip2::after {
  content: '';
  position: absolute;
  top: 73%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
}
#tooltip2Right {
  left: 110%;
}
#tooltip2Right::after {
  right: 100%;
  border-color: transparent colorLightGrey transparent transparent;
}
#tooltip2Left {
  right: 110%;
}
#tooltip2Left::after {
  left: 100%;
  border-color: transparent transparent transparent colorLightGrey;
}

/* Button on Tooltip #2 */
#tooltip2CloseIcon {
  color: colorDarkBlue;
  position: absolute;
  top: -11px;
  right: -11px;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;
  background-color: colorLightGrey;
  border: solid 1px colorDarkBlue;
}
#tooltip2CloseIcon:hover {
  background-color: colorYellow;
  cursor: pointer;
}
.tooltip2 a {
  color: colorDarkBlue;
  text-decoration: underline;
}

/* For Innovator at the center of the page */
#centerArea {
  margin: auto;
  text-align: center;
}
.center {
  background-color: red;
  width: 60px;
  margin: auto;
  text-align: center;
  top: 50%;
  right: 50%;
}

/* Styles for the bridge on larger screens */
@media screen and (min-width: 2100px) and (min-height: 870px) {
  #homeArea > #bridgeMiddle {
    max-width: pillar_width_large;
  }
  #homeArea .bridgeTopLeft,
  #homeArea .bridgeTopRight {
    height: bridge_edge_height_large;
  }
  #homeArea > #bridgeTop img {
    width: bridge_top_width_large;
  }
  #homeArea > #bottomWrapper > #bridgeBottom {
    width: pillar_width_large;
  }
  #bridgeBottom .bottomBlock > span {
    padding-left: size40;
    padding-right: size40;
  }

  .pillarBadge {
    top: pillar_badge_large_top;
    left: pillar_badge_large_left;
    max-height: pillar_badge_large_max_height;
  }
}

.digiTooltipButton {
  width: 165px;
  font: 400 1rem Roboto;
}
