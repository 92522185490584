@value colorMediumBlue, colorWhite, colorDarkBlue, colorLightBlue,colorLightBlue2, colorDarkGrey2 from "./../../variables/colors.css";
@value space5, space9, space15, space18, space36, space72 from "./../../variables/spaces.css";
@value size16, size18, size24 from "./../../variables/sizes.css";

.feedback {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.feedbackPage {
  background-color: rgba(176, 196, 222, 0.32);
}

.feedbackContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1248px;
  background-color: white;
  padding: 5px 50px;
}

.feedbackComplete {
  margin-left: auto;
  margin-right: auto;
  max-width: 1248px;
  background-color: white;
  padding: 50px 50px 65vh 50px;
  font-size: size24;
  color: colorDarkBlue;
}

.feedbackPageContent {
  margin-left: auto;
  padding-bottom: 5%;
  align-content: center;
}

.feedbackMandatory {
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  border-color: #ee4843;
  padding: 5px;
}

.feedbackQuestion {
  padding: 5px;
}

.questionText {
  color: colorDarkBlue;
}

.radialText {
  color: colorDarkBlue;
}

.banner {
  color: white;
  background-color: colorMediumBlue;
  text-align: center;
  width: 100vw;
  font-size: size24;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* do we need spacing between yes or no, its in the mock? */
/*.form-inline > * {*/
/*    margin: 10%;*/
/*}*/
