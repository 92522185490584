@value colorLightBlue, colorMediumBlue, colorYellow, colorWhite, colorDarkBlue from '../../../../../../variables/colors.css';
@value space3, space5, space9, space18 from '../../../../../../variables/spaces.css';
@value borderSize, size5, size11, size14, size18 from "../../../../../../variables/sizes.css";

.declineActions {
  background-color: colorWhite;
}

.declineButtons {
  display: inline-flex;
  justify-content: flex-end;
  padding: space9;
  width: 100%;
}

.declineButtons > button {
  margin-left: space9;
}

.declineTextAreaContainer {
  padding: space9;
  padding-bottom: 0;
  color: colorDarkBlue;
}

.declineTextArea,
.declineTextArea:focus {
  color: colorDarkBlue;
}

.charactersLength {
  padding: space3;
}
