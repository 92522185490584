@value space9 from "./../../variables/spaces.css";
@value borderSize from "./../../variables/sizes.css";
@value colorLightGrey, colorDarkBlue, colorYellow from "./../../variables/colors.css";

.tooltipBody {
  background-color: colorLightGrey;
  color: colorDarkBlue;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  cursor: default;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tooltipContainer {
}

.tooltipPopper {
}

.tooltipPopper[x-placement^='top'] .tooltipArrow::before {
  border-top-color: colorLightGrey;
}

.tooltipPopper[x-placement^='bottom'] .tooltipArrow::before {
  border-bottom-color: colorLightGrey;
}

.tooltipPopper[x-placement^='left'] .tooltipArrow::before {
  border-left-color: colorLightGrey;
}

.tooltipPopper[x-placement^='right'] .tooltipArrow::before {
  border-right-color: colorLightGrey;
}

.tooltipBody > button:hover,
.tooltipBody > button .selected,
.tooltipBody > a:hover,
.tooltipBody > a .selected {
  background-color: colorDarkBlue;
  color: colorYellow;
  border-radius: 7px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

.tooltipBody > button,
.tooltipBody > a {
  margin: 0 5px;
  padding: 5px;
  display: block;
  color: colorDarkBlue;
}
