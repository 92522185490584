@value colorWhite, colorMediumBlue, colorDarkBlue from './../../../variables/colors.css';
@value space5, space10, space15, space20, space36 from './../../../variables/spaces.css';
@value borderRadius, size10, size18 from './../../../variables/sizes.css';

.graphContainer {
  background-color: colorMediumBlue;
  margin-top: space10;
  box-shadow: 6px 6px 9px rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: size10;
  height: auto;

}

/* Canvas height had to be hard-coded :( */
.canvasContainer {
    min-height: 625px;
    width:100%
   /* background-color: whitesmoke;*/
}

/* Height = canvas height (350) - spinner height (55) */
.canvasContainerLoading {
    height: 295px;
}

.chartHeader {
    display: flex;
    justify-content: space-between;
    padding: space15 0 space15 0;
}

.chartTitle {
  font-size: size18;
  color: colorWhite;
  font-weight: bold;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  padding-left: space36; /* helps center the title */
}

.infoIconContainer {
  padding-top: space5;
  padding-bottom: space5;
  background-color: colorDarkBlue;
  border-bottom-left-radius: borderRadius;
  border-bottom-right-radius: borderRadius;
}

.chartInfoIcon {
  width: fit-content;
  height: space15;
  line-height: space15;
}

.infoIcon {
  width: fit-content;
  height: space15;
  line-height: space15;
}

.chartInfoIcon {
  float: right;
}

.chartIconContainer {
  padding-right: space20;
  padding-top: space5;
}

.spinner {
  color: colorWhite;
  text-align: center;
  padding-top: space20;
}

.labelName {
  color: colorWhite;
  font-size: size18;
  font-weight: bold;
  margin-right: space5;
}


.tableHeader {
    user-select: none;
    cursor: pointer;
}

    .tableHeader:hover {
    /*    background-color: #ececec;*/
    }

.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}




.customTable {
    border-collapse: collapse;
    width: 100%;
}

    .customTable th {
        background-color: #5873c3;
    }

    .customTable th, .customTable td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
    }

    .customTable tbody tr:nth-child(even) {
        background-color: #eaebf5;
    }

    .customTable tbody tr:nth-child(odd) {
        background-color: #d1d5ea;
    }


body {
    font-family: 'open sans', sans-serif;
    font-size: 16px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead th {
    text-align: left;
    border: 1px solid #ccc;
    /*padding: 0.6em;*/
    /*border-bottom: 2px solid black;*/
    background-color: #5873c3;
    font-weight: 500;
    font-size: inherit;
    color: colorWhite;
    padding: 0.5em;
}

thead button {
    border: 0;
    border-radius: none;
    font-family: inherit;
    font-weight: 500;
    font-size: inherit;
    color: colorWhite;
    padding: 0.5em;
    margin-bottom: 1px;
}

    thead button.ascending::after {
        content: 'A';
        display: inline-block;
        margin-left: 1em;
    }

    thead button.descending::after {
        content: 'V';
        display: inline-block;
        margin-left: 1em;
    }

tbody td {
    border: 1px solid #ccc;
    padding: 0.5em;
    /*border-bottom: 1px solid #ccc;*/
}

/*
tbody tr:nth-child(even) {
    background-color: #eaebf5;
}

tbody tr:nth-child(odd) {
    background-color: #d1d5ea;
}
tbody tr:hover {
    background-color: #eee;
}*/
.ascending {
    /*content: 'A';
    display: inline-block;
    margin-left: 1em;*/
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
.descending {
    /*content: 'V';
    display: inline-block;
    margin-left: 1em;*/
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.staffReportHolder {
    min-height: 625px;
    height: 90%;
    flex: 1 1 100%;
}