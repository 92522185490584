@value colorMediumBlue, colorYellow, colorWhite, colorDarkBlue, colorLightBlue, colorGrey, colorLightGrey from "./../../../variables/colors.css";
@value space5, space9, space18, space36 from "./../../../variables/spaces.css";
@value size16, size18, size20, size24 from "./../../../variables/sizes.css";

.filterContainer {
  color: colorDarkBlue;
  padding-bottom: 5px;
}

.upcomingSessionFilterSelected {
  background-color: colorYellow;
  border-radius: 10px;
  border-color: #00205b;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  padding-left: 3px;
  margin: 6px 0px 6px 10px;
}

.upcomingSessionFilter {
  margin: 6px 0px 6px 10px;
  padding-left: 3px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #00000000;
  border-style: solid;
}

.upcomingSessionFilter:hover {
  background-color: colorYellow;
  border-color: #00205b;
}

.upcomingSessionFilter {
  cursor: pointer;
}

.filterTitle {
  font-size: size20;
  margin-bottom: 20px;
  font-weight: bold;
}

.filterBlock {
  margin-bottom: 30px;
}

.filterBlock button {
  display: block;
  width: 100%;
  text-align: left;
  color: colorDarkBlue;
}
