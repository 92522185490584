@value space2, space3, space5, space9, space18, space20, space40 from '../../../../variables/spaces.css';

.courseInlineButton {
  float: left;
  margin: 0 space2;
  border-color: black;
}

.courseInlineButtonOutside {
  float: left;
  margin: 0 space2;
  border-color: black;
  position: absolute;
  bottom: 0;
  right: 0;
}

.formContainer {
  padding-top: space20;
  padding-bottom: space20;
}

.reportName {
  vertical-align: middle !important;
}

.reportTable {
  margin-top: space40;
}
