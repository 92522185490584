.closeButton {
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent url('../../../../assets/dashboardPanel/closeButton.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.innerButton {
  width: 22px;
  height: 22px;
}
