/*CSS styles shared across all admin tabs*/
.adminFirstNav button {
  /* background-color: red; */
  padding: 0.5rem 1rem;
  margin-top: 0.3rem;
}

.adminFirstNav .active {
  background-color: lightgray;
}

.adminRoot {
  display: block;
  /*width: 100%;*/
  width: 1250px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

:global(.tab-content) {
  margin: 10px;
}
:global(.card) {
  margin: 10px 0;
}

.submitBtn {
  float: right;
  margin: 10px 0;
  clear: both;
}

.submitBtnMargin28 {
  margin: 30px 0 0 0;
}

.tabMargin {
  margin-top: 10px;
}

.card {
  margin: 10px 0;
}

.cardTitle {
  max-width: 80%;
}

.subText {
  font-style: italic;
  color: #444;
  margin: auto 0;
  font-size: 14px;
}

.cardSubHead {
  float: left;
  font-weight: normal;
}
