@value space8 from '../../../../variables/spaces.css';

.digiQuillDisabled {
  background-color: #e9ecef;
  pointer-events: none;
}

.formButtonRight {
  float: right;
  margin: 0 space8;
}
