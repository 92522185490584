@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorLightBlue, colorLightBlue2, colorMediumBlue, colorDarkBlue, colorYellow from './../../../variables/colors.css';
@value space3, space9, space15, space18, space20, space25, space36 from './../../../variables/spaces.css';
@value dividerSize, borderSize, borderRadius6, size16, size18, size22, size24, size26 from "./../../../variables/sizes.css";

.sessionListWrapper {
  height: 72vh;
}

.titleHolder h3 {
  font-size: size18;
  font-weight: 600;
  text-align: left;
  color: colorDarkBlue;
  padding-left: space25;
  padding-right: space18;
  padding-top: space3;
  padding-bottom: space3;
}

.title {
  background-color: colorLightBlue;
  border-radius: borderRadius6;
}

.clickableTitle {
  width: 100%;
}

.titleSelected {
  cursor: pointer;
  background-color: colorYellow;
  border-radius: borderRadius6;
}

.title:hover {
  cursor: pointer;
  background-color: colorLightBlue2;
}

.sessionListHolder {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.sessionListHolder::-webkit-scrollbar-thumb {
  background: colorDarkBlue;
  border-radius: borderRadius6;
}

.sessionListHolder::-webkit-scrollbar-track {
  border: borderSize solid colorGrey;
}

.session {
  margin-left: -7px;
  padding-right: space25;
  padding-top: space25;
}

.contentHolder {
  display: flex;
  margin: 0;
  padding: 0;
}

.titleHolder {
  flex: 1 1 auto;
}

.mandatoryContainer {
  color: colorDarkBlue;
  margin-top: space9;
  margin-right: space20;
}

.detailsHolder {
  display: flex;
  justify-content: space-between;
  font-size: size16;
  line-height: size22;
  color: colorMediumBlue;
  margin-top: space9;
  padding-left: space25;
}

.domainContainer {
  font-weight: 600;
}

.past {
  color: colorDarkGrey2;
  font-style: italic;
}

.recommendContainer {
  color: colorWhite;
  background-color: colorMediumBlue;
  border-radius: borderRadius6;
  margin-top: space9;
  margin-left: space25;
  padding-left: space9;
}

.sessionList hr {
  background-color: transparent;
  border-top: dividerSize solid colorLightGrey;
  margin-bottom: 0;
}

.spinnerHolder {
  padding-top: space36;
  text-align: center;
}

.loadingSpinner {
  margin: auto;
}

.msgHolder {
  padding: space15;
  color: colorDarkBlue;
}

ul {
  padding-left: space15;
}

.special-star {
  font-size: size26;
  font-weight: bold;
}
