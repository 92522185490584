@value colorYellow, colorMediumBlue, colorDarkBlue, colorLightBlue3, colorLightGrey, colorWhite from '../../../../variables/colors.css';
@value space1, space3, space5, space15 from '../../../../variables/spaces.css';

.subtext {
  margin-left: space15;
  margin-top: space5;
  color: gray;
  font-style: italic;
}

.point {
  font-size: 2em;
}

.courseInlineButton {
  position: absolute;
  bottom: 0;
  right: 0;
}

.courseInlineButton:hover {
  background-color: colorDarkBlue;
}

.tableOverflow {
  max-height: 800px;
  overflow-y: auto;
}

.tableOverflow thead th {
  background-color: white;
  position: sticky;
  top: -1px;
}

.tableOverflow thead th {
  box-shadow: inset 0px space1 #dee2e6, 0 space1 #dee2e6;
  vertical-align: text-top;
}

.absolutePosition {
  position: absolute;
}

.tableHeader {
  user-select: none;
  cursor: pointer;
}

.tableHeader:hover {
  background-color: #ececec;
}

.arrow {
  border: solid black;
  border-width: 0 space3 space3 0;
  display: inline-block;
  padding: space3;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
