@value space2, space3, space5, space9, space18, space20, space40 from '../../../../variables/spaces.css';

.courseInlineButton {
    float: left;
    margin: 0 space2;
    border-color: black;
}
.margin {
    padding-left: space40; margin-bottom: space9;
}
