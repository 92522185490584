.characterCount {
  width: 100%;
  font-style: italic;
  color: gray;
  text-align: right;
  /*float: right;*/
}

.characterCountZero {
  color: #b33a3a;
}
