@value space10, space15, space20, space36 from './../../variables/spaces.css';

.detailsContainer {
  margin-right: space20;
  margin-bottom: space36;
  margin-top: space10;
}

.sessionsList {
  padding-right: 0;
}

.recommendDiv {
  width: 100%;
  padding-right: 0;
}
