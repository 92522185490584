@value colorLightGrey, colorDarkBlue, colorYellow from "../../variables/colors.css";
@value space2, space8, space10 from "../../variables/spaces.css";

.filters {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.filters * {
  margin: 0 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  padding-top: space2;
  padding-bottom: space2;
  border-radius: space10;
  font-weight: bold;
}

.filters *:hover {
  background-color: colorYellow;
  color: colorDarkBlue;
  border-radius: space10;
  text-decoration: none;
}

.filterActive {
  color: white;
  background-color: colorDarkBlue;
  border-radius: space10;
  text-decoration: none;
}
