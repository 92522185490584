@value size55 from '../../../../variables/sizes.css';
@value space5 from '../../../../variables/spaces.css';

.floatLeft {
  float: left;
}

.buttonMargin {
  margin-left: space5;
}

.collapse {
  clear: both;
}

.hoverPointer {
  cursor: pointer;
}

.mandatoryCircle {
  display: inline-block;
}

.courseInlineImage {
  height: 24px;
}

.courseInlineButton {
  float: right;
  margin: 0 12px;
  padding: 0;
}

.innerCourseCard {
  margin: 0;
}

.aligncellitemscourse {
  margin-left: 30px !important;
}

.grantPoints {
  float: right;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.exportParticipantListCsvButton {
  font-weight: bold;
  color: var(--color-dark-blue);
  border-color: var(--color-dark-blue);
}

.courseBottomButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.courseBottomButtons__right {
  justify-content: end;
}

.courseDisplayTableButton {
  /* float: left; */
  margin: 0.1rem 0.3rem 0.1rem 0.3rem;
}

.maxCapacityIndicator {
  height: 2.3rem;
}

.participantbuttonsubmit {
  display: inline-block;
  font-weight: 400;
  border: none;
  background-color: var(--color-medium-blue);
  color: white;
}

.participantbuttonsubmit:disabled {
  display: inline-block;
  font-weight: 400;
  border: none;
  background-color: #dddfe0 !important;
  color: #00205b !important;
}
.thinCard {
  padding: 0;
}
