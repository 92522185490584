@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorDarkBlue, colorMediumBlue, colorYellow from '../../../../variables/colors.css';
@value space5, space9, space12, space15, space18 from '../../../../variables/spaces.css';
@value dividerSize, borderSize,size1, size8, size11, size14, size18, borderRadius from "../../../../variables/sizes.css";

.DashboardSessionsScrollContainer {
}

.historyContainer {
  height: 100%;
  position: relative;
  background-color: colorWhite;
  display: flex;
  flex-direction: column;
}

.navItem {
  font-size: 14px;
}

.sessionTitleHolder {
  background-color: colorMediumBlue;
  padding: space9 0 0 0;
  color: colorWhite;
  font-size: size18;
  top: 0;
  left: 0;
  right: 0;
}

.sessionTitleHolder h2 {
  padding-bottom: space9;
  font-size: size18;
  text-align: center;
}

.sessionHolder h3 {
  flex: 0 1 auto;
  font-size: size18;
}

.sessionListHolder {
  flex: 1 1 auto;
}

.outsid.sessionListHolder {
  padding: space9;
  color: colorDarkBlue;
  overflow: auto;
  padding-top: 20px;
}
.darkBlueContent {
  color: colorDarkBlue;
}
.darkBlueContent p {
  margin: 0 !important;
  font-style: italic;
}
.darkBlueContent p:first-child {
  font-weight: bold;
}

.sessionListHolder h3 {
  color: colorMediumBlue;
  font-size: size18;
}

.sessionListHolder hr {
  padding: 0;
  margin-top: size8;
  margin-bottom: size8;
}

.endLine {
  background-color: transparent;
  border-top: dividerSize solid colorLightGrey;
  margin-bottom: 0;
}

.session {
  padding-left: space18;
  padding-right: space18;
  padding-top: space18;
}

.buttonHolder {
  text-align: right;
  margin-top: space18;
  padding-bottom: space5;
}

.buttonHolder button {
  margin-left: space18;
}

.contentHolder {
  display: flex;
}

.titleHolder {
  flex: 1 1 auto;
}

.titleHolder h3 {
  flex: 1 1 100%;
}

.spinnerHolder {
  text-align: center;
}

.spinner {
  width: 3rem !important;
  height: 3rem !important;

  text-align: center;
  margin-top: space18;
}

.active {
  /* vertical-align: middle; */
  height: fit-content;
  font-size: size14 !important;
  padding: space5 0 space5 0;
}

.navigator {
  background-color: colorWhite;
  display: flex;
}
.nav {
  /* flex: 1 1 100%; */
  color: colorWhite;
  text-align: center;
}

.navigator button {
  cursor: pointer;
  margin: 5px 4px 5px 4px;
  font-size: size14;
}

.navigator button > span {
  padding: 3px 2px 3px;
}

.navigator button:hover {
  text-decoration: none;
}

.navigator .active {
  background-color: colorYellow;
  border-radius: borderRadius;
  text-align: center;
  min-width: 50%;
  border: borderSize solid colorDarkBlue;
  margin: 15px 4px 5px 4px;
}
.navSpliter {
  flex: 0 0 1px;
  background-color: colorDarkBlue;
  margin: 15px 0px 5px;
}

.mandatoryContainer {
  flex: 0 0 auto;
}

.timeContainer {
  /* font: 16px; */
  margin-top: 5px;
  color: colorMediumBlue;
}

.outsideSessionList {
  padding: 15px;
  color: colorDarkBlue;
}
