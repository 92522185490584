@value space200 from '../../../../variables/spaces.css';
@value colorRed from '../../../../variables/colors.css';

.roleSelect {
  max-width: space200;
}

.warningText {
  color: colorRed;
}
