.innovatorDialogTextArea .innovatorDialogYellowHightlight {
  color: #f3d250;
}
.innovatorDialogTextArea .innovatorDialogBigFont {
  font-size: 2.25rem;
  margin-bottom: 12px;
}
.innovatorDialogTextArea .innovatorDialogExtraMargin {
  height: 0.75rem;
}
.innovatorDialogTextArea {
  font: normal 1.25rem Roboto;
  line-height: 1.75rem;
}
.innovatorBadgeImgArea {
  margin: 1rem auto auto 1.1rem;
  text-align: center;
}
.innovatorRegistrationBadge {
  width: 5rem;
  height: 5rem;
  padding: 0.2rem;
  border-radius: 50%;
  border: 3px solid white;
}
