@value colorLightBlue, colorMediumBlue, colorYellow, colorWhite, colorDarkBlue from '../../../../variables/colors.css';
@value space3, space5, space9, space15, space18 from '../../../../variables/spaces.css';
@value borderSize, size5, size11, size14, size18 from "../../../../variables/sizes.css";

.borderDiv {
  margin: space3 space5;
  padding: space3;
  border: size5 dashed transparent;
}

.paddingDiv:last-child {
  padding-bottom: 80px;
}

.notificationContainer {
  background-color: colorLightBlue;
  border: borderSize solid colorMediumBlue;
  transition: height 0.2s;
}

.paddingContainer {
  padding: space9;
}

.registrationClosedContainer {
  padding: space9;
  border: 1px solid #005eb8;
  background: #f3d250;
  font-weight: bold;
}

.disabledNotificationButton {
  opacity: 0.5;
  pointer-events: none;
}

.selected.notificationContainer {
  border: 3px solid colorYellow;
  transition: 0.2s ease-in;
}

.notificationContainer a {
  text-decoration: none;
}

.emphasizedText {
  color: colorMediumBlue;
  font-weight: bold;
}

.borderAnimation {
  animation-name: blinkBorder;
  animation-duration: 5s;
}

@keyframes blinkBorder {
  0% {
    border: size5 dashed transparent;
  }
  25% {
    border: size5 dashed colorYellow;
  }
  50% {
    border: size5 dashed transparent;
  }
  75% {
    border: size5 dashed colorYellow;
  }
  100% {
    border: size5 dashed transparent;
  }
}

.buttonHolder {
  display: flex;
  justify-content: space-between;
  padding: space5;
  width: 100%;
}

.buttonHolderClose {
  display: flex;
  justify-content: flex-end;
  padding: space5;
  width: 100%;
}

.closeText {
  flex: 1 1 auto;
  justify-content: center;
  font-style: italic;
  display: flex;
  flex-direction: column;
}

.declineExpand {
  opacity: 0.5;
  transform: translateY(-1rem);
}

.declineExpandActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms, height 300ms;
}

.declineCollapseActive {
  height: 0;
  opacity: 0;
  transform: translateY(-0.8rem);
  transition: opacity 200ms, transform 200ms, height 200ms;
}
