@value colorWhite, colorDarkGrey, colorBlack, colorBabyBlue from './../../../variables/colors.css';
@value space3, space9, space18 from './../../../variables/spaces.css';
@value size12 from './../../../variables/sizes.css';

.spacer {
  height: space18;
}

.calendarHolder {
  height: 100%;
  flex-direction: column;
}

.calendarTitle {
  flex: 0 0 auto;
}

.fullCalendarHolder {
  height: 90%;
  flex: 1 1 100%;
}

.calendarTitle button {
  background-color: colorWhite;
  color: colorBlack;
  font-size: size12;
  display: inline-block;
}

.calendarTitle button > span {
  padding: space3 space9;
}

.calendarTitle button.disabled {
  background-color: colorDarkGrey;
  cursor: default !important;
  text-decoration: none;
}

.calendarTitle .month {
  background-color: colorBabyBlue;
  padding: space3 space9;
  color: colorBlack;
  font-size: size12;
  width: 75px;
  display: inline-block;
  text-align: center;
}

.calendarTitle button.selected {
  background-color: colorDarkGrey;
}

.calendarTitle button:first-child {
  border-top-left-radius: space3;
  border-bottom-left-radius: space3;
}

.calendarTitle button:last-child {
  border-top-right-radius: space3;
  border-bottom-right-radius: space3;
}

.calendarTitle > div {
  float: left;
  width: 33.33333%;
}

.calendarTitle .center {
  text-align: center;
  color: colorWhite;
}

.calendarTitle .right {
  text-align: right;
}
