@value space200 from '../../../../variables/spaces.css';
@value colorRed from '../../../../variables/colors.css';

/*.fileInput {
    max-width: space300;
}
*/
.warningText {
  color: colorRed;
}
.input_container {
    border: 1px solid #e5e5e5;
}

input[type=file]::file-selector-button {
    background-color: #fff;
    color: #000;
    border: 0px;
    border-right: 1px solid #e5e5e5;
    padding: 10px 15px;
    margin-right: 20px;
    transition: .5s;
}

input[type=file]::file-selector-button:hover {
    background-color: #eee;
    border: 0px;
    border-right: 1px solid #e5e5e5;
}
.file_label {
    width: 100%;
}