@value colorLightBlue, colorDarkBlue, colorYellow, colorGrey, colorLightGrey from './../../variables/colors.css';
@value space1, space2, space3, space5, space10, space15, space18, space20, space25, space30, space90 from './../../variables/spaces.css';
@value size5, size14, borderSize, borderRadius from './../../variables/sizes.css';

.container {
  display: flex;
  float: right;
  justify-content: center;
}

.dashboard.container {
  display: flex;
  float: right;
  justify-content: center;
  align-items: flex-end;
}

.timeframe {
  color: colorDarkBlue;
  margin-top: space1;
  padding-top: space30;
  margin-right: space15;
}

/* 'Timeframe' text */
.dashboard .timeframe {
  font-weight: bold;
  margin-bottom: space5;
  padding: space2 space10 space2 space10;
  background-color: colorYellow;
  border-radius: borderRadius;
  border: borderSize solid colorDarkBlue;
}

.dashboard.faded .timeframe {
  color: colorGrey;
  background-color: colorLightGrey;
}

.startDate {
  margin-right: space15;
}

.input label {
  color: colorDarkBlue;
  font-size: size14;
  margin: 0 0 0 size5;
}

/* For the 'From' and 'To' */
.dashboard .input label {
  color: white;
  font-weight: bold;
  margin: 0 0 0 80px;
}

/* To change the font color of startDate & endDate input */
.dashboard .input :global(.form-control) {
  color: colorDarkBlue;
  font-weight: bold;
}

/* To change the font color of startDate & endDate input when they're disabled*/
.dashboard .input :global(.form-control[disabled]) {
  color: colorGrey;
}

/* Refresh button for Manager Dashboard */
.refreshButton {
  margin: space25 space20 0 space15;
  border-radius: borderRadius;
  background-color: colorYellow;
}

/* Disabled refresh button for Manager Dashboard */
.disabledrefreshButton {
  margin: space25 space20 0 space15;
  background-color: colorLightBlue;
  border-radius: borderRadius;
}

/* Button border for My Dashboard */
.refreshButtonHolder {
  border: space3 solid white;
  margin: 0 space10 -5px space10;
  padding: space5;
}

/* Refresh button for My Dashboard */
.refreshButtonHolder .refreshButton {
  margin: 0;
  background-color: colorYellow;
}

/* Disabled refresh button for My Dashboard */
.refreshButtonHolder .disabledrefreshButton {
  margin: 0;
  background-color: white;
}

/* Yellow button border on date change for refresh button for My Dashboard */
.refreshButtonHolder.enabled {
  border-color: colorYellow;
}
