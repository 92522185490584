:root {
  --color-light-blue-2: #90ccf4;
  --color-medium-blue: #005eb8;
  --color-dark-blue: #00205b;
  --color-pink: #ec008c;
  --color-light-blue: #e0f5ff;
  --color-light-gray: #ececec;
  --color-sun-yellow: #f3d250;
  --color-red: #cd0000;
  --color-white: #ffffff;
}

.app-container {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a:focus {
  outline-color: #ec008c;
}
input:focus {
  outline: none;
  background-color: #f3d250;
  color: var(--color-dark-blue);
}
footer a:focus {
  outline-color: initial;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.flex {
  display: flex;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-fixed {
  flex: 0 0 auto;
}
.flex.center {
  justify-content: center;
  align-items: center;
}

.btn.btn-primary {
  background-color: var(--color-medium-blue);
}

button {
  background: none;
  border: none;
  padding: 0;
}

.break {
  clear: both;
}

.btn {
  border-radius: 9px;
  padding: 8px 23px;
}

.dw-button {
  background-color: var(--color-medium-blue);
  color: white;
  border-radius: 10px;
  border: solid 1px var(--color-medium-blue);
}

.dw-button > span {
  padding: 5px 0 5px 0;
  min-width: 90px;
}

.dw-button.lg {
  min-width: 150px;
}

.dw-button.lg2 {
  min-width: 100px;
  min-height: 40px;
}

.dw-button:hover,
.dw-button.active,
.dw-button.lg:hover,
.dw-button.lg.active,
.dw-button.lg2.active {
  background-color: #f3d250;
  color: var(--color-dark-blue);
  border: solid 1px var(--color-dark-blue);
}

.dw-button.medBlue,
.dw-button.medBlue:disabled,
.dw-button.medBlue:hover:disabled {
  background-color: var(--color-medium-blue);
  color: white;
  border: solid 1px var(--color-medium-blue);
}

.dw-button.medBlue:hover,
.dw-button.medBlue.active {
  background-color: var(--color-light-blue-2);
  color: var(--color-dark-blue);
  border: solid 1px var(--color-dark-blue);
}

.dw-button.lightBlue,
.dw-button.lightBlue:disabled,
.dw-button.lightBlue:hover:disabled {
  background-color: var(--color-light-blue-2);
  color: var(--color-dark-blue);
  border: solid 1px var(--color-dark-blue);
}

.dw-button.lightBlue:hover,
.dw-button.lightBlue.active {
  background-color: var(--color-medium-blue);
  color: white;
  border: solid 1px var(--color-medium-blue);
}

.dw-button.darkBlue,
.dw-button.darkBlue:disabled,
.dw-button.darkBlue:hover:disabled {
  background-color: var(--color-dark-blue);
  color: var(--color-sun-yellow);
  border: solid 1px var(--color-dark-blue);
}

.dw-button.red,
.dw-button.red:disabled,
.dw-button.red:hover:disabled {
  background-color: var(--color-red);
  color: var(--color-white);
  border: solid 1px var(--color-dark-blue);
}

.dw-button.darkBlue:hover,
.dw-button.darkBlue.active {
  background-color: var(--color-light-blue-2);
  color: var(--color-dark-blue);
  border: solid 1px var(--color-dark-blue);
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body,
html {
  height: 100%;
  margin: 0;
  background-color: #dddfe0;
}

.show-for-sr {
  background-color: #fff;
  color: #000;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  top: 0;
  left: 0;
}

.noDisplay {
  display: none;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box #pageContent {
  flex: 1 0 auto;
}

/* Calendar styles */

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

.fc-content {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.fc-day-number {
  font-size: 10px;
}

.fc-content {
  font-size: 12px;
}

.fc-content:hover {
  cursor: pointer;

  display: block;
}

.fc-view table {
  background-color: #fff;
}

.fc-unthemed thead.fc-head th,
.fc-unthemed thead.fc-head td {
  border-top: 0;
  border-left: inherit;
  border-right: inherit;
  border-bottom: inherit;
}

.fc-unthemed td.fc-today {
  background: #e7f6fd;
}

.fc-head tr th {
  background-color: var(--color-medium-blue);
  color: #fff;
  border: 0;
  text-transform: uppercase;
  font-size: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
}
/* End calendar styles */

@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-Thin.ttf) format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-Light.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-Black.ttf) format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/font/Roboto-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

.Toastify__toast--success {
  box-shadow: none;
  background-color: #00000000;
  color: var(--color-dark-blue);
  border: 10px solid;
  border-image: url('./assets/ConfirmationBoxW.png') 80 80 fill;
  font-weight: bold;
}

.Toastify__toast--info {
  box-shadow: none;
  background-color: #00000000;
  color: var(--color-dark-blue);
  border: 10px solid;
  border-image: url('./assets/ConfirmationBoxW.png') 80 80 fill;
  font-weight: bold;
}

.Toastify__toast--error {
  box-shadow: none;
  background-color: #00000000;
  color: var(--color-dark-blue);
  border: 10px solid;
  border-image: url('./assets/WarningBoxW.png') 80 80 fill;
  font-weight: bold;
}

.Toastify__progress-bar {
  background-color: var(--color-dark-blue);
}

.Toastify__close-button {
  color: var(--color-dark-blue);
}

.toast-spinner {
  color: var(--color-dark-blue);
}

.tooltip.show {
  opacity: 1;
}

/* Remove arrow from Reactstrap tooltip */
.tooltip .arrow {
  display: none;
}

.tableHeader {
  user-select: none;
  cursor: pointer;
}

.tableHeader:hover {
  background-color: --color-light-gray;
}

.arrow {
  border: solid black;
  border-width: 0 space3 space3 0;
  display: inline-block;
  padding: space3;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
