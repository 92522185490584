@value space9, space3, space18, space36 from '../../../../variables/spaces.css';
@value colorDarkBlue, colorBlack, colorMediumBlue, colorYellow from '../../../../variables/colors.css';
@value borderSize, borderRadius, size12, size13, size14 from '../../../../variables/sizes.css';

.employeeContainer {
  padding: space3 space9;
  font-weight: bold;
  color: colorDarkBlue;
}

.selectAllContainer {
  padding: space3 space9;
  font-weight: bold;
  color: colorMediumBlue;
}

.selectAllScroll {
  flex: 1;
  min-height: 0px;
}

.containerRow {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 5fr;
}

.step1Container {
  height: 72vh;
  margin-right: 15px;
}

.step1Content {
  display: flex;
  flex-direction: column;
}

.step2Container {
  height: 72vh;
  margin-right: 15px;
}

.removeStyles {
  all: unset;
}

.step2Content {
  display: flex;
  flex-direction: column;
}

.emailContainer {
  flex: 1;
  min-height: 0;
  border: borderSize solid colorBlack;
  border-radius: borderRadius;
  margin: space9 space18;
  overflow: hidden;
}

.emailScroll {
  padding: space9 space18;
  height: 100%;
}

.emailScroll a {
  color: colorMediumBlue;
}

.recommendButtonContainer {
  text-align: center;
}

.messageInfoBox {
  display: flex;
  flex-direction: column;
  background-color: colorYellow;
  color: colorDarkBlue;
  font-weight: bold;
  padding: space9 space36;
}

.disabledMessage {
  font-size: size13;
  font-weight: normal;
  font-style: italic;
  margin-left: 24px;
  color: #6c757d;
}

.noStaffContainer {
  margin-top: space9;
  color: colorDarkBlue;
}

.noStaffContainer a {
  color: colorMediumBlue;
}
