@value colorLightBlue2, colorBlack, colorYellow, colorDarkBlue, colorWhite from "./../../variables/colors.css";

.innovatorHolder {
  position: absolute;
  z-index: 1000;
  top: -700px;
  left: 0;
  transition: all 2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.innovatorHolder .innovatorFramedImg {
  width: 170px;
}
.innovatorHolder .innovatorFramedImgRight {
  /* innovatorRight */
  float: right;
}
.innovatorHolder .innovatorFramedImgLeft {
  /* innovatorLeft */
  float: left;
}

.innovatorHolder .innovatorDialogConnector {
  margin: 0;
  max-width: 45px;
  position: relative;
}
.innovatorHolder .innovatorDialogConnectorRight {
  /* when innovator is located left side */
  top: 50px;
  left: -4px;
}
.innovatorHolder .innovatorDialogConnectorLeft {
  /* when innovator is located right side */
  top: 57px;
  left: 2px;
  -webkit-transform: rotate(180deg);
}

.innovatorHolder .innovatorArrowImg {
  margin: 0;
  max-width: 45px;
  position: absolute;
}
.innovatorHolder .arrowUpOnRightInnovator {
  top: -42px;
  right: 60px;
}
.innovatorHolder .arrowDownOnRightInnovator {
  bottom: -2px;
  right: 60px;
  -webkit-transform: rotate(180deg);
}
.innovatorHolder .arrowUpOnLeftInnovator {
  top: -42px;
  left: 64px;
}
.innovatorHolder .arrowDownOnLeftInnovator {
  /* When text length is less than 300   */
  bottom: -30px;
  left: 64px;
  -webkit-transform: rotate(180deg);
}
.innovatorHolder .arrowDownOnLeftInnovator2 {
  /* When text length is more than 300   */
  bottom: 42px;
  left: 64px;
  -webkit-transform: rotate(180deg);
}

.innovatorHolder .dialogEdge {
  position: relative;
  z-index: 1010;
  border: solid transparent;
  border-top-width: 17px;
  border-right-width: 80px;
  border-bottom-width: 55px;
  border-left-width: 20px;
  border-image: url('../../assets/DialogueBox.png');
  border-image-slice: 34 195 145 40;
  color: colorDarkBlue;
}
.innovatorHolder .dialogEdgeRight {
  /* when innovator is located left side */
  float: right;
  margin: 0 0 0 -7px;
}
.innovatorHolder .dialogEdgeLeft {
  /* when innovator is located right side */
  float: left;
  margin: 40px -4px 0 0;
}

.innovatorHolder .dialogContent {
  background-color: colorDarkBlue;
  /* background-color: chocolate; */
  color: colorWhite;
  margin: -1px -30px -19px -1px;
  float: left;
  padding: 4px 41px 0px 10.5px;
  position: relative;
  z-index: 1010;
  line-height: 1.4em;
  border: none;
}

.innovatorHolder #closeDialogXBtn {
  background-color: transparent;
  border-radius: 50%;
  color: colorDarkBlue;
  position: absolute;
  right: -25px;
  top: 4px;
  margin: 0;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;
  background-color: colorLightBlue2;
}

.innovatorHolder .buttonAreaInDialog {
  margin: -20px -5px -4px -5px;
  padding-left: 4%;
  text-align: center;
  background-color: colorDarkBlue;
  /* background-color: cornflowerblue; */
  position: relative;
  z-index: 1001;
  bottom: -35px;
}

.innovatorHolder .nextDialogBtn {
  text-align: center;
  background-color: colorWhite;
  display: inline-block;
  padding: 2px 11px;
  margin: 0px 18px 0 18px;
  border-radius: 10px;
  color: colorBlack;
  font: normal 1.375rem Roboto;
  z-index: 1003;
}

.innovatorHolder #closeDialogXBtn:hover,
.innovatorHolder .nextDialogBtn:hover {
  background-color: colorYellow;
  font-weight: 500;
  cursor: pointer;
}
