@value colorWhite, colorMediumBlue from '../../variables/colors.css';

.recommendButton {
  width: 125px;
  height: 47px;
  /* margin to adjust for shadow on the right */
  margin-right: -5px;
  margin-bottom: -9.45px;
  background: transparent url('../../assets/recommend/normal.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  color: colorWhite;
  font-size: 13px;
  opacity: 1;
  position: relative;
}

.recommendButton.disabled {
  filter: grayscale(100%);
}

.recommendButton.disabled:hover {
  background: transparent url('../../assets/recommend/normal.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.recommendButton:hover,
.recommendButton.selected {
  background: transparent url('../../assets/recommend/selected.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.textSpan {
  position: absolute;
  top: 15.3px;
  left: 13.5px;
  background-color: colorMediumBlue;
  line-height: 0.5;
}
