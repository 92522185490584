@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorLightBlue,colorDarkBlue, colorMediumBlue, colorYellow from './../../variables/colors.css';
@value space9, space18 from './../../variables/spaces.css';
@value borderRadius, size11, size18 from "./../../variables/sizes.css";

.closeIcon {
  color: colorMediumBlue;
  border-radius: 50%;
  background-color: colorLightBlue;
  border: solid 1px colorLightBlue;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0.05rem;
  width: 2.2rem;
  height: 2.2rem;
}

.closeIcon:hover {
  background-color: colorMediumBlue;
  color: colorWhite;
}
