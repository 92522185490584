@value space9, space18 from "./../../variables/spaces.css";
@value borderRadius, size18 from "./../../variables/sizes.css";
@value colorWhite, colorDarkBlue, colorDarkGrey2, colorLightBlue2 from "./../../variables/colors.css";

.scrollContainer {
  overflow: auto;
}

/* width */
.scrollContainer::-webkit-scrollbar {
  width: 16px;
  border: solid 1px colorDarkGrey2;
}

/* Track */
.scrollContainer::-webkit-scrollbar-track {
  border: solid 3px transparent;
}

/* Handle */
.scrollContainer::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px colorDarkBlue;
  border: solid 2px transparent;
}

.wrapper {
  border-radius: borderRadius;
}
