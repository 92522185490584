@value size8, size60, size74 from './../../variables/sizes.css';

/* Questioning the effectiveness of sizing variables */

.nonLtc {
  margin-left: size8;
  width: size60;
  height: size60;
}

.nonLtcDetails {
  width: size74;
  height: size74;
}
