@value space3, space9 from "./../../variables/spaces.css";
@value colorWhite, colorYellow, colorDarkBlue from "./../../variables/colors.css";

.numberHolder {
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  font-weight: 450;
  margin-top: 3.5px;
  color: colorYellow;
  background-color: colorDarkBlue;
}

.yellow .numberHolder {
  color: colorDarkBlue;
  background-color: colorYellow;
}

.yellow.bellContainer {
  background-image: url('../../assets/notifications/bellYellow.svg');
}

.bellContainer {
  display: flex;
  justify-content: center;
  padding: 0 space3;
  background-image: url('../../assets/notifications/bellBlue.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 24.37px;
  height: 22px;
}

.bellContainer.enableHoverStyle:hover,
a:hover .bellContainer.enableHoverStyle {
  background-image: url('../../assets/notifications/bellYellow.svg');
  color: colorDarkBlue;
}

.bellContainer.yellow.enableHoverStyle:hover,
a:hover .bellContainer.yellow.enableHoverStyle {
  background-image: url('../../assets/notifications/bellBlue.svg');
  color: colorYellow;
}

.bellContainer.yellow.enableHoverStyle:hover .numberHolder,
a:hover .bellContainer.yellow.enableHoverStyle .numberHolder {
  color: colorYellow;
  background-color: colorDarkBlue;
}

.bellContainer.enableHoverStyle:hover .numberHolder,
a:hover .bellContainer.enableHoverStyle .numberHolder {
  color: colorDarkBlue;
  background-color: colorYellow;
}
