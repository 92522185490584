@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorLightBlue2, colorDarkBlue, colorMediumBlue, colorYellow, colorBlack from '../../../../variables/colors.css';
@value space5, space9, space15, space18, space20, space25, space72 from '../../../../variables/spaces.css';
@value size11, size12, size13, size14, size18, size20, size22, size24 maxLogoSize, minLogoSize, borderSize, borderRadius from "../../../../variables/sizes.css";

.heightContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation-name: moveIn;
  animation-duration: 0.3s;
}

.sessionDescText {
  padding-left: space25;
  padding-right: space25;
}

.borderContainer {
  flex: 1 0 63vh;
  border-top: 40px solid transparent;
  border-right: 37px solid transparent;
  border-left: 22px solid transparent;
  border-bottom: 40px solid transparent;
  border-image: url('../../../../assets/dashboardPanel/dashboardPanelLarge.svg') 6.8%
    4.5% 6% 2.81% stretch;
  background-clip: content-box;
  font-size: size18;
}

.titleHolder {
  background-color: colorMediumBlue;
  color: colorWhite;
  padding-bottom: space15;
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  padding-left: space25;
}

.pillarHolder {
  background-color: colorDarkBlue;
  padding: space18;
  padding-left: space25;
  color: colorWhite;
}

.contentHolder {
  height: 100%;
  width: 100%;
  background-color: colorWhite;
  display: flex;
  flex-direction: column;
  color: colorDarkBlue;
}

.contentHolder h2 {
  font-size: size24;
}

.contentHolder h3 {
  font-size: size18;
  font-weight: 400;
}

.logoHolder {
  max-height: 180px;
  max-width: 200px;
  min-height: minLogoSize;
  min-width: minLogoSize;
  margin-right: space72;
}

.rowWithImage {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-left: space25;
  padding-right: space25;
}

.descScrollContainer {
  margin: space5 space25 space18 space25;
  padding: 0 space18;
  border: borderSize solid colorBlack;
  flex: 1 0 120px;
  color: colorBlack;
}

.imageRightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.closeButtonContainer {
  padding: space5 space5 0 0;
}

.buttonsHolder {
  display: flex;
  justify-content: flex-end;
  padding-right: space25;
  margin-bottom: -6px;
}

.buttonsHolder > * {
  margin-left: space18;
}

@keyframes moveIn {
  from {
    -webkit-transform: translate(1em, 0);
    -moz-transform: translate(1em, 0);
    -o-transform: translate(1em, 0);
    -ms-transform: translate(1em, 0);
    transform: translate(1em, 0);
  }
}
