@value space5, space10, space30, space300 from '../../../../../variables/spaces.css';

.employeeContainer {
  padding: space10;
}

.buttonContainer {
  margin-top: space30;
  float: right;
}

.buttons {
  margin-left: space10;
}

.dateContainer {
  margin-left: space300;
}

.startDateContainer {
  float: left;
  padding: space5;
}

.endDateContainer {
  float: left;
  padding: space5;
}
