@value colorLightGrey, colorDarkBlue, colorYellow from "../../../../variables/colors.css";
@value size55 from '../../../../variables/sizes.css';

.filters {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.filters * {
  margin: 0 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  font-weight: bold;
}

.filters *:hover {
  background-color: colorYellow;
  color: colorDarkBlue;
  border-radius: 10px;
  text-decoration: none;
}

.filterActive {
  color: white;
  background-color: colorDarkBlue;
  border-radius: 10px;
  text-decoration: none;
}

.mandatoryCircle {
  width: size55;
  display: inline-block;
}
* {
  box-sizing: border-box;
}

.column {
  float: left;
  width: 3%;
  padding: 10px;
  margin-right: 20px;
}

.spinner {
  float: left;
  width: 5%;
  padding: 10px;
  margin-right: 20px;
  color: colorDarkBlue;
}

.resultsColumn {
  float: left;
  width: 20%;
  padding: 10px;
  margin-right: 20px;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.stable {
  position: sticky;
  top: 5px;
  z-index: 1;
  background: white;
}

.scrollable {
  position: relative;
}
