@value space9, space18 from "./../../variables/spaces.css";
@value borderRadius, size18 from "./../../variables/sizes.css";
@value colorWhite, colorDarkBlue, colorDarkGrey2, colorLightBlue2 from "./../../variables/colors.css";

.customScroll {
  overflow: auto;
}

/* width */
.customScroll::-webkit-scrollbar {
  width: 16px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  border: solid 1px colorDarkGrey2;
  border-bottom-right-radius: 10px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: colorLightBlue2;
  border-radius: space9;
}

.wrapper {
  border-radius: borderRadius;
}
