.collapseButton {
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expand {
  background: transparent
    url('../../../../../assets/dashboardPanel/expandButton.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.collapse {
  background: transparent
    url('../../../../../assets/dashboardPanel/collapseButton.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.innerButton {
  width: 22px;
  height: 22px;
}
