@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorDarkBlue, colorMediumBlue, colorYellow from './../../variables/colors.css';
@value space2, space5, space9, space10, space15, space18, space20, space30 from './../../variables/spaces.css';
@value size11, size18, size24, borderSize, borderRadius from "./../../variables/sizes.css";
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: colorDarkBlue;
  background-image: url('../../assets/homepageBackground.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeArea {
  margin-left: auto;
  margin-right: auto;
  max-width: 1250px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.titleHolder {
  color: colorWhite;
  margin: size18 size18 space30 space5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.titleHolder h1 {
  flex: 1 1 100%;
  font-size: size24;
}

.DashboardComponent {
  border-radius: space9;
  height: 63vh;
  border: 20px solid transparent;
  padding: 0px 0px 4px 0px;
  border-image: url(../../assets/MyDashboardPanel.png) 40 50 50 50 stretch;
}

.contentRow {
  margin-top: space9;
  align-items: flex-end;
}

.notificationIconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex {
  display: flex;
  flex-direction: row; /*by def*/
  align-items: baseline;
  justify-content: flex-end;
  margin-bottom: space10;
}

.flex .radio {
  margin: 0 0 0 space10;
}

/*
  The label should not appear in the UI. Set the font size to 0.
 */
.radio :global(.custom-control-label) {
  font-size: 0;
}

.radio :global(.custom-control-label)::before {
  background-color: white !important;
}

.radio :global(.custom-control-label)::after {
  background-color: colorDarkBlue !important;
}

.flex .texty {
  color: colorDarkBlue;
  font-weight: bold;
  padding: space2 space10 space2 space10;
  background-color: colorYellow;
  border-radius: borderRadius;
  border: borderSize solid colorDarkBlue;
  text-align: end;
}

.flex.faded .texty {
  color: colorGrey;
  background-color: colorLightGrey;
}

.exportHistoryCsvButton {
  background-color: white;
  font-weight: bold;
  color: var(--color-dark-blue);
  border-style: solid;
  border-color: var(--color-dark-blue);
  float: right;
  /*Align the flexbox container havin*/
  align-self: center;
  margin-left: 1rem;
  height: 80%;
  /*Make the Export button a flexbox containing (image & text)*/
  display: flex;
  align-items: center;
}

.titleHolder2 {
    display: flex;
    background-color: colorMediumBlue;
    color: colorWhite;
    font-size: size18;
}

.highlighted .titleHolder2 {
    color: colorDarkBlue;
    background-color: colorYellow;
}

.titleHolder2 h2 {
    padding: space9;
    font-size: size18;
    text-align: center;
}
.innerTitleHolder2 {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
}

.collapsible .innerTitleHolder2 {
    cursor: pointer;
}
.nonCompliant {
    color: white;
    background-color: red;
    text-align: center;
    justify-content: center;
}
.compliant {
    color: colorDarkBlue;
    background-color: colorYellow;
    text-align: center;
    justify-content: center;
}