@value colorYellow, colorLightGrey, colorMediumBlue, colorDarkBlue, colorWhite from "./../../variables/colors.css";
@value space3, space5, space10, space18 from "./../../variables/spaces.css";
@value borderSize from "./../../variables/sizes.css";

.App {
  text-align: center;
}

.leadership-breadcrumb-holder {
  all: unset !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.leadership-breadcrumb-bit {
  all: unset !important;
}

.leadership-title__dash {
  color: white;
  font: normal bold 1.25rem Roboto;
  margin-right: 10px;
}

.leadership-title__name {
  color: white;
  font: normal 1.2rem Roboto;
}

.navbarBrand {
  color: white !important;
}

.skipMain {
  text-align: center;
}

.skipMain a {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  color: colorDarkBlue;
  text-decoration: underline;
}

.skipMain a:focus,
.skipMain a:active {
  position: static !important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto;
}

.headerLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: colorDarkBlue !important;
  margin: 0 2px; /* Any larger horizontal margin will break the UI spacing on the homepage if the user is an admin and manager */
  font: normal 16px/12px Roboto;
  border: borderSize solid #00000000;
  border-radius: 10px;
}

.headerLink.helpBtn {
  padding: 0.5rem;
}

.secondHeaderLink {
  text-align: center;
  color: colorWhite;
  padding: 0 10px;
  font: normal 1.25rem Roboto;
  margin: auto;
}

.profileLink {
  font: bold 1rem Roboto;
  display: inline-block;
  box-sizing: border-box;
  padding-top: 2px;
  padding-bottom: 2px;
}

.profileLink img {
  margin-left: space18;
}

.profileLink:hover {
  background-color: colorYellow;
  color: colorDarkBlue;
  border-radius: 10px;
  text-decoration: none;
  font-weight: bold;
}

.secondaryNav {
  background-color: colorMediumBlue;
  display: flex;
  padding: 10px;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.secondaryNavWrapper {
  background-color: colorMediumBlue;
  min-width: 100%;
}
.userInfo {
  margin: auto;
}
.secondaryNav div {
  flex: 33.33333%;
  margin: auto;
}

.secondaryNav div:first-child {
  text-align: left;
}

.secondaryNav div:nth-child(2) {
  text-align: center;
  display: contents;
  color: colorWhite;
}

.secondaryNav div:last-child {
  text-align: right;
}

.firstNav {
  background-color: colorLightGrey;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  -webkit-filter: invert(1%) !important; /* safari 6.0 - 9.0 */
  filter: invert(1%) !important;
}

.navIconLink {
  margin: 0 5px;
  max-height: 50px;
  outline: none;
}

.badgeSet {
  margin: auto;
}

.badgeSet .mostRecentBadgeOnNavbar {
  border-radius: 50%;
  border: 3px solid white;
}

.navHighlight a:hover,
.headerLinkActive,
.headerLink.helpBtn:hover,
.breadcrumb:hover {
  background-color: colorYellow;
  border: borderSize solid colorDarkBlue !important;
  color: colorDarkBlue !important;
  text-decoration: none;
}

.headerProfilePicture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.headerProfilePictureLink {
  padding: 0 !important;
  margin: 0 10px;
}
.innovatorIcon {
  width: 50px !important;
  height: 50px !important;
}
.innovatorIcon:hover {
  cursor: pointer;
}

.center {
  background-color: red;
  width: 60px;
  margin: auto;
  text-align: center;
  top: 50%;
  right: 50%;
}

.hiddenContact {
  margin-left: auto;
}

.digiTooltipButton {
  width: 180px;
  font: 400 0.9rem Roboto;
}

.headerLinkContent {
  display: flex;
}

.headerLinkText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notificationIcon {
  margin: -5px 0;
}

.managerDashboardHeaderTitle {
  text-align: center;
  color: colorWhite;
  padding: 0 space10;
  font: normal bold 1.25rem Roboto;
}

.breadcrumb {
  justify-content: center;
  color: colorWhite;
  font: normal 1rem Roboto;
  margin: auto;
  padding: 2px space5;
  border: borderSize solid transparent;
  border-radius: 8px;
}

.firstBreadcrumb {
  justify-content: center;
  color: colorWhite;
  font: normal 1rem Roboto;
  margin: auto;
  padding: 0 space5 0 space10;
  border: borderSize solid transparent;
  border-radius: 8px;
}

.breadcrumbDivider {
  justify-content: center;
  color: colorWhite;
  font: normal 1rem Roboto;
  margin: auto;
  padding: 0 space1;
}
