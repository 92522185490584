@value colorWhite, colorGrey, colorLightGrey, colorLightBlue2, colorDarkGrey2, colorDarkBlue, colorMediumBlue, colorYellow, colorLightBlue from '../../../../variables/colors.css';
@value space1, space5, space9, space15, space18 from '../../../../variables/spaces.css';
@value dividerSize, borderSize, size5, size11, size14, size18, borderRadius from "../../../../variables/sizes.css";

.sessionHolder {
  background-color: colorWhite;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.pastButtonHolder button {
  margin-top: space18;
  margin-left: space18;
}

.noContent {
  padding: 15px;
  color: colorDarkBlue;
}

.loadingUnregisterButton {
  background-color: colorMediumBlue;
  color: colorWhite;
  min-width: 90px;

  padding: 5px 0 5px 0;
  border-radius: 10px;
  border: solid 1px colorMediumBlue;
}

.unregisterButton {
  background-color: colorLightBlue2;
  color: colorDarkBlue;
  min-width: 90px;

  padding: 5px 0 5px 0;
  border-radius: 10px;
  border: solid 1px colorDarkBlue;
}

.unregisterButton:hover {
  background-color: colorMediumBlue;
  color: colorWhite;
  min-width: 90px;
  padding: 5px 0 5px 0;
  border-radius: 10px;
  border: solid 1px colorMediumBlue;
}

@keyframes blinkBorder {
  0% {
    border: size5 dashed transparent;
  }
  25% {
    border: size5 dashed colorYellow;
  }
  50% {
    border: size5 dashed transparent;
  }
  75% {
    border: size5 dashed colorYellow;
  }
  100% {
    border: size5 dashed transparent;
  }
}

.animateBorder {
  animation-name: blinkBorder;
  animation-duration: 5s;
}

.historyContainer {
  height: 100%;
  position: relative;
  background-color: colorWhite;
  display: flex;
  flex-direction: column;
}

.navItem {
  font-size: 14px;
}

.sessionTitleHolder {
  background-color: colorMediumBlue;
  padding: space9 0 0 0;
  color: colorWhite;
  font-size: size18;
  top: 0;
  left: 0;
  right: 0;
}

.sessionTitleHolder h2 {
  padding-bottom: space9;
  font-size: size18;
  text-align: center;
}

.sessionHolder h3 {
  flex: 0 1 auto;
  font-size: size18;
}

.sessionListHolder {
  flex: 1 1 auto;
}

.outsid.sessionListHolder {
  padding: space9;
  color: colorDarkBlue;
  overflow: auto;
  padding-top: 20px;
}

.sessionListHolder h3 {
  color: colorMediumBlue;
  font-size: size18;
}

.sessionListHolder hr {
  background-color: transparent;
  border-top: dividerSize solid colorLightGrey;
  margin-bottom: 0;
}

.session {
  padding-left: space18;
  padding-right: space18;
  padding-top: space18;
}

.buttonHolder {
  text-align: right;
  margin-top: space18;
  padding-bottom: space5;
}

.buttonHolder button {
  margin-left: space15;
}

.contentHolder {
  display: flex;
}

.titleHolder {
  flex: 1 1 auto;
}

.titleHolder h3 {
  flex: 1 1 100%;
}

.spinnerHolder {
  text-align: center;
}

.spinner {
  width: 3rem !important;
  height: 3rem !important;

  text-align: center;
  margin-top: space18;
}

.active {
  flex: 1 1 100%;
}

.navigator {
  background-color: colorWhite;
  display: flex;
}
.nav {
  flex: 1 1 100%;
  color: colorDarkBlue;
  text-align: center;
}

.navigator button {
  cursor: pointer;
  margin: 15px 4px 5px 4px;
  font-size: size14;
}

.navigator button > span {
  padding: 3px 2px 3px;
}

.navigator button:hover {
  text-decoration: none;
}

.navigator .active {
  background-color: colorYellow;
  border-radius: borderRadius;
  border: borderSize solid colorDarkBlue;
}
.navSpliter {
  flex: 0 0 1px;
  background-color: colorDarkBlue;
  margin: 15px 0px 5px;
}

.mandatoryContainer {
  flex: 0 0 auto;
}

.timeContainer {
  /* font: 16px; */
  margin-top: 5px;
  color: colorMediumBlue;
}

.noteContainer {
  background: colorLightBlue 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: space5;
  margin-top: space15;
  margin-left: space5;
  margin-right: space5;
  color: colorDarkBlue;
}

.readButton {
  font-weight: 500;
  color: colorDarkBlue;
  padding-left: space1;
  padding-right: space1;
}
