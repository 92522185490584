@value colorMediumBlue, colorLightBlue from "./../../variables/colors.css";
@value borderSize, size20, size30 from "./../../variables/sizes.css";
@value space5, space9, space30, space36 from "./../../variables/spaces.css";

@value angleSize: 70px;

.title {
  font: Bold size30 Roboto;
  color: colorMediumBlue;
  opacity: 1;
  margin: space30 space36 0;
}
.searchBar {
  margin: space30 space36 0;
  float: right;
}
.searchIcon {
  color: colorMediumBlue;
}

.searchButton {
  border-radius: 0 space5 space5 0;
  background-color: colorLightBlue;
  float: right;
  border: borderSize solid colorMediumBlue;
  border-left: 0;
}

.searchButton > span {
  padding: space5;
}

.inputField {
  width: 240px;
  border-radius: space5 0 0 space5;
  border: borderSize solid colorMediumBlue;
  padding: space5;
}

input[type='search']::-webkit-search-cancel-button {
  cursor: pointer;
}
.carousel {
  width: 100%;
  height: 500px;
}

.angleLeft {
  cursor: pointer;
  color: colorMediumBlue;
  float: right;
  margin-right: 250px;
}

.angleRight {
  cursor: pointer;
  color: colorMediumBlue;
  margin-left: 250px;
}
.presenterMoveBtn {
  max-height: angleSize;
  min-height: angleSize;
  min-width: angleSize;
  max-width: angleSize;
}
.backButtonContainer {
  margin-left: size20;
}

.style {
  cursor: pointer;
}

.css-1qzevvg {
  cursor: pointer;
}
