@value space2, space5, space9, space10, space15, space18, space20, space30 from './../../variables/spaces.css';
@value size11, size18, size24, borderSize, borderRadius from "./../../variables/sizes.css";
@value colorWhite, colorGrey, colorLightGrey, colorDarkGrey2, colorDarkBlue, colorMediumBlue, colorYellow from './../../variables/colors.css';



.center {
  text-align: center;
  margin-top: 4rem;
}

.statsContainer {
  padding-left: 0;
  padding-right: 0;
}

.statsHolder {
  width: 1250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: space5;
  margin-bottom: space18;
  padding-left: space20;
  padding-right: -space15;
}

.bottomRows {
  margin: 0;
  padding-top: space9;
}

.chartContainer {
  margin-top: space9;
}

.col {
  padding-right: 0;
}

.cardRows {
  margin-right: space5;
}

.special-star {
  font-size: size24;
  font-weight: bold;
}

.flex {
    display: flex;
    flex-direction: row; /*by def*/
    align-items: baseline;
    justify-content: flex-end;
    padding-top: space30;
    margin-right: space15;
}

    .flex .radio {
        margin: 0 0 0 space10;
    }

    .flex .texty {
        color: colorDarkBlue;
        font-weight: bold;
        padding: space2 space10 space2 space10;
        background-color: colorYellow;
        border-radius: borderRadius;
        border: borderSize solid colorDarkBlue;
        text-align: end;
    }

    .flex.faded .texty {
        color: colorGrey;
        background-color: colorLightGrey;
    }