.innovatorAnimationBox {
  position: absolute;
  bottom: -20%;
  left: 0%;
  width: 100%;
  z-index: 900;
  height: 190%;
  overflow: hidden;
}

/* Fireworks CSS */
.innovatorAnimationBox .IApyro > .IAbefore,
.innovatorAnimationBox .IApyro > .IAafter {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.innovatorAnimationBox .IApyro > .IAafter {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -195px -200.6666666667px #00ffdd, 210px -100.6666666667px #ff5100,
      -211px 32.3333333333px #00ffa2, 119px -114.6666666667px #ff4000,
      -235px -175.6666666667px #00ff62, -191px -378.6666666667px #d0ff00,
      -36px -289.6666666667px #009dff, 215px -84.6666666667px #ffd500,
      -107px -316.6666666667px #fff200, 209px -391.6666666667px #00ff22,
      -115px 68.3333333333px #cc00ff, -31px -146.6666666667px #0004ff,
      -117px -10.6666666667px #1a00ff, -70px 21.3333333333px #00ff11,
      -103px -383.6666666667px #009dff, -55px -154.6666666667px #44ff00,
      32px 65.3333333333px #001aff, 160px -272.6666666667px #a200ff,
      -117px 63.3333333333px #00ff44, -23px -97.6666666667px #e6ff00,
      198px -279.6666666667px #e6ff00, -54px 63.3333333333px #7700ff,
      105px -71.6666666667px #ff002b, -203px -18.6666666667px #2b00ff,
      -190px 56.3333333333px #bf00ff, 164px -104.6666666667px #eaff00,
      -141px -371.6666666667px #00f2ff, -85px -359.6666666667px #c4ff00,
      -48px -109.6666666667px #ff0073, -169px 8.3333333333px #003cff,
      -157px -102.6666666667px #ff00b3, 34px -265.6666666667px #5900ff,
      69px -45.6666666667px #0048ff, -242px -107.6666666667px #d5ff00,
      160px -333.6666666667px #ff00b7, 190px -309.6666666667px #ffea00,
      164px -22.6666666667px #00ff3c, 50px -230.6666666667px #00ff09,
      -7px -242.6666666667px #ff00c4, -112px -198.6666666667px #aeff00,
      54px -205.6666666667px #c400ff, 137px -164.6666666667px #00ffea,
      112px -345.6666666667px #00ff88, 58px -342.6666666667px #0088ff,
      -122px -349.6666666667px #99ff00, 194px -136.6666666667px #ff6f00,
      -71px 58.3333333333px #ff0073, -62px -217.6666666667px #00ff04,
      86px -260.6666666667px #7300ff, 27px -404.6666666667px #8400ff,
      -211px -57.6666666667px #b3ff00;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: -195px -200.6666666667px #00ffdd, 210px -100.6666666667px #ff5100,
      -211px 32.3333333333px #00ffa2, 119px -114.6666666667px #ff4000,
      -235px -175.6666666667px #00ff62, -191px -378.6666666667px #d0ff00,
      -36px -289.6666666667px #009dff, 215px -84.6666666667px #ffd500,
      -107px -316.6666666667px #fff200, 209px -391.6666666667px #00ff22,
      -115px 68.3333333333px #cc00ff, -31px -146.6666666667px #0004ff,
      -117px -10.6666666667px #1a00ff, -70px 21.3333333333px #00ff11,
      -103px -383.6666666667px #009dff, -55px -154.6666666667px #44ff00,
      32px 65.3333333333px #001aff, 160px -272.6666666667px #a200ff,
      -117px 63.3333333333px #00ff44, -23px -97.6666666667px #e6ff00,
      198px -279.6666666667px #e6ff00, -54px 63.3333333333px #7700ff,
      105px -71.6666666667px #ff002b, -203px -18.6666666667px #2b00ff,
      -190px 56.3333333333px #bf00ff, 164px -104.6666666667px #eaff00,
      -141px -371.6666666667px #00f2ff, -85px -359.6666666667px #c4ff00,
      -48px -109.6666666667px #ff0073, -169px 8.3333333333px #003cff,
      -157px -102.6666666667px #ff00b3, 34px -265.6666666667px #5900ff,
      69px -45.6666666667px #0048ff, -242px -107.6666666667px #d5ff00,
      160px -333.6666666667px #ff00b7, 190px -309.6666666667px #ffea00,
      164px -22.6666666667px #00ff3c, 50px -230.6666666667px #00ff09,
      -7px -242.6666666667px #ff00c4, -112px -198.6666666667px #aeff00,
      54px -205.6666666667px #c400ff, 137px -164.6666666667px #00ffea,
      112px -345.6666666667px #00ff88, 58px -342.6666666667px #0088ff,
      -122px -349.6666666667px #99ff00, 194px -136.6666666667px #ff6f00,
      -71px 58.3333333333px #ff0073, -62px -217.6666666667px #00ff04,
      86px -260.6666666667px #7300ff, 27px -404.6666666667px #8400ff,
      -211px -57.6666666667px #b3ff00;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: -195px -200.6666666667px #00ffdd, 210px -100.6666666667px #ff5100,
      -211px 32.3333333333px #00ffa2, 119px -114.6666666667px #ff4000,
      -235px -175.6666666667px #00ff62, -191px -378.6666666667px #d0ff00,
      -36px -289.6666666667px #009dff, 215px -84.6666666667px #ffd500,
      -107px -316.6666666667px #fff200, 209px -391.6666666667px #00ff22,
      -115px 68.3333333333px #cc00ff, -31px -146.6666666667px #0004ff,
      -117px -10.6666666667px #1a00ff, -70px 21.3333333333px #00ff11,
      -103px -383.6666666667px #009dff, -55px -154.6666666667px #44ff00,
      32px 65.3333333333px #001aff, 160px -272.6666666667px #a200ff,
      -117px 63.3333333333px #00ff44, -23px -97.6666666667px #e6ff00,
      198px -279.6666666667px #e6ff00, -54px 63.3333333333px #7700ff,
      105px -71.6666666667px #ff002b, -203px -18.6666666667px #2b00ff,
      -190px 56.3333333333px #bf00ff, 164px -104.6666666667px #eaff00,
      -141px -371.6666666667px #00f2ff, -85px -359.6666666667px #c4ff00,
      -48px -109.6666666667px #ff0073, -169px 8.3333333333px #003cff,
      -157px -102.6666666667px #ff00b3, 34px -265.6666666667px #5900ff,
      69px -45.6666666667px #0048ff, -242px -107.6666666667px #d5ff00,
      160px -333.6666666667px #ff00b7, 190px -309.6666666667px #ffea00,
      164px -22.6666666667px #00ff3c, 50px -230.6666666667px #00ff09,
      -7px -242.6666666667px #ff00c4, -112px -198.6666666667px #aeff00,
      54px -205.6666666667px #c400ff, 137px -164.6666666667px #00ffea,
      112px -345.6666666667px #00ff88, 58px -342.6666666667px #0088ff,
      -122px -349.6666666667px #99ff00, 194px -136.6666666667px #ff6f00,
      -71px 58.3333333333px #ff0073, -62px -217.6666666667px #00ff04,
      86px -260.6666666667px #7300ff, 27px -404.6666666667px #8400ff,
      -211px -57.6666666667px #b3ff00;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: -195px -200.6666666667px #00ffdd, 210px -100.6666666667px #ff5100,
      -211px 32.3333333333px #00ffa2, 119px -114.6666666667px #ff4000,
      -235px -175.6666666667px #00ff62, -191px -378.6666666667px #d0ff00,
      -36px -289.6666666667px #009dff, 215px -84.6666666667px #ffd500,
      -107px -316.6666666667px #fff200, 209px -391.6666666667px #00ff22,
      -115px 68.3333333333px #cc00ff, -31px -146.6666666667px #0004ff,
      -117px -10.6666666667px #1a00ff, -70px 21.3333333333px #00ff11,
      -103px -383.6666666667px #009dff, -55px -154.6666666667px #44ff00,
      32px 65.3333333333px #001aff, 160px -272.6666666667px #a200ff,
      -117px 63.3333333333px #00ff44, -23px -97.6666666667px #e6ff00,
      198px -279.6666666667px #e6ff00, -54px 63.3333333333px #7700ff,
      105px -71.6666666667px #ff002b, -203px -18.6666666667px #2b00ff,
      -190px 56.3333333333px #bf00ff, 164px -104.6666666667px #eaff00,
      -141px -371.6666666667px #00f2ff, -85px -359.6666666667px #c4ff00,
      -48px -109.6666666667px #ff0073, -169px 8.3333333333px #003cff,
      -157px -102.6666666667px #ff00b3, 34px -265.6666666667px #5900ff,
      69px -45.6666666667px #0048ff, -242px -107.6666666667px #d5ff00,
      160px -333.6666666667px #ff00b7, 190px -309.6666666667px #ffea00,
      164px -22.6666666667px #00ff3c, 50px -230.6666666667px #00ff09,
      -7px -242.6666666667px #ff00c4, -112px -198.6666666667px #aeff00,
      54px -205.6666666667px #c400ff, 137px -164.6666666667px #00ffea,
      112px -345.6666666667px #00ff88, 58px -342.6666666667px #0088ff,
      -122px -349.6666666667px #99ff00, 194px -136.6666666667px #ff6f00,
      -71px 58.3333333333px #ff0073, -62px -217.6666666667px #00ff04,
      86px -260.6666666667px #7300ff, 27px -404.6666666667px #8400ff,
      -211px -57.6666666667px #b3ff00;
  }
}
@keyframes bang {
  to {
    box-shadow: -195px -200.6666666667px #00ffdd, 210px -100.6666666667px #ff5100,
      -211px 32.3333333333px #00ffa2, 119px -114.6666666667px #ff4000,
      -235px -175.6666666667px #00ff62, -191px -378.6666666667px #d0ff00,
      -36px -289.6666666667px #009dff, 215px -84.6666666667px #ffd500,
      -107px -316.6666666667px #fff200, 209px -391.6666666667px #00ff22,
      -115px 68.3333333333px #cc00ff, -31px -146.6666666667px #0004ff,
      -117px -10.6666666667px #1a00ff, -70px 21.3333333333px #00ff11,
      -103px -383.6666666667px #009dff, -55px -154.6666666667px #44ff00,
      32px 65.3333333333px #001aff, 160px -272.6666666667px #a200ff,
      -117px 63.3333333333px #00ff44, -23px -97.6666666667px #e6ff00,
      198px -279.6666666667px #e6ff00, -54px 63.3333333333px #7700ff,
      105px -71.6666666667px #ff002b, -203px -18.6666666667px #2b00ff,
      -190px 56.3333333333px #bf00ff, 164px -104.6666666667px #eaff00,
      -141px -371.6666666667px #00f2ff, -85px -359.6666666667px #c4ff00,
      -48px -109.6666666667px #ff0073, -169px 8.3333333333px #003cff,
      -157px -102.6666666667px #ff00b3, 34px -265.6666666667px #5900ff,
      69px -45.6666666667px #0048ff, -242px -107.6666666667px #d5ff00,
      160px -333.6666666667px #ff00b7, 190px -309.6666666667px #ffea00,
      164px -22.6666666667px #00ff3c, 50px -230.6666666667px #00ff09,
      -7px -242.6666666667px #ff00c4, -112px -198.6666666667px #aeff00,
      54px -205.6666666667px #c400ff, 137px -164.6666666667px #00ffea,
      112px -345.6666666667px #00ff88, 58px -342.6666666667px #0088ff,
      -122px -349.6666666667px #99ff00, 194px -136.6666666667px #ff6f00,
      -71px 58.3333333333px #ff0073, -62px -217.6666666667px #00ff04,
      86px -260.6666666667px #7300ff, 27px -404.6666666667px #8400ff,
      -211px -57.6666666667px #b3ff00;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
