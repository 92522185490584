@value space9 from "./../../variables/spaces.css";

.dashboardBadge {
  max-height: 80px;
  outline: none;
}

.badgeNameHolder {
  text-align: center;
  font-size: 16px;
}

.badgeContentHolder {
  text-align: center;
  font-size: 14px;
}

.earnedBadge {
}

.badge {
  text-align: center;
  align-items: center;
  margin: 0 auto;
  padding: space3;
}

.unearnedBadge {
  opacity: 0.3;
}
