@value colorYellow from './../../variables/colors.css';
@value size11, size55 from './../../variables/sizes.css';

.mandatoryCircleHolder {
  flex: 0 0 auto;
  justify-content: center;
  height: fit-content;
  /* width: fit-content; */
  text-align: right;
}

.mandatoryCircleHolder .mandatoryCircle {
  background-color: colorYellow;
  display: inline-block;
  /* text-align: right; */
  /* height: auto; */
  /* height:fit-content; */
  /* margin-left: 25%; */
  /* margin: 5px 0 5px 0; */
  padding: 24% 0 24% 0;
}

.mandatoryCircleHolder .mandatoryCircle span {
  /* position: relative; */
  /* top: 13px; */
  font-size: size11;
  font-weight: 500;
}
