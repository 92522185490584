@value colorDarkBlue, colorMediumBlue, colorWhite from "./../../variables/colors.css";
@value borderRadius from "./../../variables/sizes.css";
@value space3, space5, space9 from "./../../variables/spaces.css";

.tooltipBody {
  background-color: colorMediumBlue;
  color: colorMediumBlue;
  text-align: center;
  border-radius: calc(borderRadius + space3);
  padding: space3;
  z-index: 1;
  cursor: default;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 16px;
}

.tooltipPopper[x-placement^='bottom'],
.tooltipPopper[x-placement^='top'] {
  padding: 0.9rem 0;
}

.tooltipPopper .tooltipArrow {
  height: 0.9rem;
}

.tooltipPopper[x-placement^='top'] .tooltipArrow::before {
  border-top-color: colorMediumBlue;
  border-width: 0.9rem 0.3rem 0;
}

.tooltipPopper[x-placement^='bottom'] .tooltipArrow::before {
  border-bottom-color: colorMediumBlue;
  border-width: 0 0.3rem 0.9rem;
}

.tooltipPopper[x-placement^='left'] .tooltipArrow::before {
  border-left-color: colorMediumBlue;
}

.tooltipPopper[x-placement^='right'] .tooltipArrow::before {
  border-right-color: colorMediumBlue;
}
